import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Space } from 'antd';
import styles from './index.module.less';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
// import moment from 'moment';
// import { Activity } from 'lucide-react';
import { getMetrics } from './redux/slice';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

// const { RangePicker } = DatePicker;

const blenderDashboard = () => {
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  // const [form] = Form.useForm();
  // const [fileList, setFileList] = useState();
  // const [allBatches, setAllBatches] = useState([]);
  // const [docType, setDocType] = useState('');
  // const [printOptions, setPrintOptions] = useState('');
  // const dispatch = useDispatch();
  const [metrics, setMetrics] = useState([]);
  const [batchSizeData, setBatchSizeData] = useState([]);
  const dispatch = useDispatch();
  console.log(metrics);

  useEffect(() => {
    handleGetAllMetrics();
  }, []);

  const handleGetAllMetrics = () => {
    const payload = { asset_id: searchParams.get('id') };
    dispatch(getMetrics(payload)).then((response) => {
      console.log(response);
      setMetrics(response?.payload?.data);
      setBatchSizeData(response?.payload?.data?.productStats);
    });
  };
  // const handleOnChange = (e) => {
  //   console.log(e);
  //   // const startDate = `${moment(e[0]._d).format('YYYY-MM-DD')}`;
  //   // const endDate = `${moment(e[1]._d).format('YYYY-MM-DD')}`;
  //   // const payload = { startDate, endDate };
  //   const payload = { id: searchParams.get('asset_id') };
  //   dispatch(getMetrics(payload)).then((response) => {
  //     console.log(response);
  //     setMetrics({
  //       totalBatches: response?.payload?.data?.productionMetrics.totalBatches,
  //       averageBatchDuration:
  //         response?.payload?.data?.productionMetrics.averageBatchDuration,
  //       alarmFrequency:
  //         response?.payload?.data?.qualityAndSafety.averageAlarmDuration,
  //       utilizationRate:
  //         response?.payload?.data?.equipmentPerformance.utilizationRate
  //     });
  //     setBatchSizeData(response?.payload?.data?.productStats);
  //   });
  // };
  // const metrics = {
  //   totalBatches: 78,
  //   averageBatchDuration: '1h 45m',
  //   alarmFrequency: '3.2/day',
  //   utilizationRate: '68%'
  // };

  // const batchSizeData = [
  //   { name: 'Hydroxyzine', size: 324 },
  //   { name: 'Bupropion', size: 249.5 },
  //   { name: 'Tizanidine', size: 396 },
  //   { name: 'Tramadol', size: 308 },
  //   { name: 'Esomeprazole', size: 641.4 }
  // ];

  const rpmData = [
    { time: '05:00', RPM: 3 },
    { time: '06:00', RPM: 4 },
    { time: '07:00', RPM: 6 },
    { time: '08:00', RPM: 9 },
    { time: '09:00', RPM: 9.5 },
    { time: '10:00', RPM: 3 },
    { time: '11:00', RPM: 5.6 },
    { time: '12:200', RPM: 4 }
  ];

  return (
    <Layout sider={false}>
      <Card
        className={`${styles.cardStyles_approver}`}
        title="Blender Performance Dashboard"
        // extra={<RangePicker onChange={(e) => handleOnChange(e)} />}
      >
        {/* {metrics?.totalBatches ? ( */}
        <Row gutter={24}>
          {metrics?.map((item, index) => {
            return (
              <Col span={6} key={index}>
                <Card className={styles.BGimage}>
                  <div className="mb-2 text-gray-600">{item.name}</div>
                  <Space size={90}>
                    <span style={{ fontSize: '30px' }}>{item?.last_value}</span>
                    <span style={{ fontSize: '13px' }}>
                      {`@${moment(item.time).format('hh:mm A')}`}
                    </span>
                  </Space>
                  {/* </CardContent> */}
                </Card>
              </Col>
            );
          })}

          {/* <Col span={6}>
            <Card>
              <Space>
                <Activity style={{ color: 'purple', fontWeight: '500' }} />
                <div>
                  <div style={{ color: 'gray' }}>Utilization Rate</div>
                  <div style={{ color: 'black' }}>
                    {metrics.utilizationRate}
                  </div>
                </div>
              </Space>
            </Card>
          </Col> */}
        </Row>
        {/* <br /> */}
        {/* <Card title="RPM Trends" className={`${styles.cardStyles_approver}`}>
          <div className="h-64">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={rpmData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="RPM" stroke="#c91761" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card> */}
        <br />
        {/* <Card className={`${styles.cardStyles_approver}`}> */}
        <Tabs defaultActiveKey="1" type="card">
          {metrics?.map((item, index) => {
            return (
              <Tabs.TabPane tab={item?.name} key={index}>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={item?.average_data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Average"
                        stroke="#c91761"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>

        {/* </Card> */}
        {/* ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Select a date range above to view insights"
          />
        )} */}
      </Card>

      {/* Charts */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}
      <br />
      {/* <Row gutter={24}> */}
      {/* <Space direction="vertical" size="12"> */}
      {metrics?.totalBatches > 100 ? (
        <>
          <Col span={24}>
            <Card
              className={`${styles.cardStyles_approver}`}
              title="Batch Sizes by Product"
            >
              <div className="h-64">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={batchSizeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="productName" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="AverageBatchSize" fill="#ea649c" />
                    <text
                      x="50%"
                      y="100%"
                      dy={20}
                      textAnchor="middle"
                      fontSize={18}
                      fontWeight="bold"
                      fill="#333"
                    >
                      Custom Title Below Bar Chart
                    </text>

                    {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
          <br />
          {/* <br /> */}
          <Col span={24}>
            <Card
              title="RPM Trends"
              className={`${styles.cardStyles_approver}`}
            >
              <div className="h-64">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={rpmData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="RPM" stroke="#c91761" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </>
      ) : (
        ''
      )}
      {/* </Space> */}
      {/* </Row> */}
    </Layout>
  );
};
export default blenderDashboard;
