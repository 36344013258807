import React, { useEffect } from 'react';
import styles from './index.module.less';
import { Card, Empty, Tag } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterials } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
// import VehicleStorage from '../../images/vehicle-storage-area.png';
// import DocumentVerification from '../../images/document-verification.png';
// import MaterialDetails from '../../images/material-details.png';
// import BatchDetails from '../../images/batch-details.png';
// import DamageReport from '../../images/damage-report.png';
// import WeighingReport from '../../images/weighing-report.png';
// import GenerateMRN from '../../images/generate-MRN.png';
// import QCtruck from '../../images/3.svg';
// import CustomButton  from '@/common/CustomButton';

const PendingListPage = () => {
  const { allMaterials, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.material
  );
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMaterials();
  }, [page]);
  const handleGetAllMaterials = () => {
    let payload = {
      // status: 'pending',
      page
    };
    dispatch(getAllMaterials(payload)).then();
  };

  const columns = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.invoice_number?.localeCompare(b?.invoice_number),
      render: (text, record) => {
        return (
          <a
            href={
              record?.material_status === 'doc_ver_approved'
                ? `vehicle-storage/${record.id}`
                : record?.material_status === 'veh_ver_approved'
                ? `invoice-details/${record.id}`
                : record?.material_status === 'inv_ver_approved'
                ? `batch-details/${record.id}`
                : record?.material_status === 'dmg_ver_approved'
                ? `batch-details/${record.id}`
                : record?.material_status === 'wh_ver_approved'
                ? `generate-mrn/${record.id}`
                : '#'
            }
            onClick={() => {
              localStorage.setItem('invoice_num', record.invoice_number),
                localStorage.setItem('material_name', record?.material?.name),
                localStorage.setItem('material_code', record?.material?.code);
            }}
          >
            {text}
          </a>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Date of Receipts',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: '100px',
      sorter: (a, b) =>
        moment(a[`createdAt`]).unix() - moment(b[`createdAt`]).unix(),
      render: (createdAt) => <>{moment(createdAt).format('DD MMM YYYY')}</>,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Up next',
      dataIndex: 'up_next',
      key: 'up_next',
      width: 150,
      ellipsis: true,
      sorter: (a, b) => a?.up_next?.localeCompare(b?.up_next),
      render: (text, record) => {
        return record?.material_status == 'doc_ver_approved' ||
          record?.material_status == 'doc_ver_pending'
          ? 'Vehicle Storage Area'
          : record?.material_status == 'veh_ver_approved' ||
            record?.material_status == 'veh_ver_pending'
          ? 'Invoice Details'
          : record?.material_status == 'inv_ver_approved' ||
            record?.material_status == 'inv_ver_pending'
          ? 'Batch Details'
          : record?.material_status == 'dmg_ver_approved' ||
            record?.material_status == 'dmg_ver_pending'
          ? 'Weighing Report'
          : record?.material_status == 'wh_ver_approved' ||
            record?.material_status == 'wh_ver_pending'
          ? 'Mrn generation'
          : 'Document Verification';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => {
        return (
          <Tag color={record?.qa_status === null ? '#28a745' : '#2db7f5'}>
            {record?.qa_status === null ? 'QA APPROVED' : 'QA PENDING'}
          </Tag>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  // const cards = [
  //   {
  //     title: 'Doc. Verification',
  //     url: '/create',
  //     icon: DocumentVerification
  //   },
  //   {
  //     title: 'Vehicle Storage Area',
  //     url: '/upnext-receipts?material_status=doc_ver_approved',
  //     icon: VehicleStorage
  //   },
  //   {
  //     title: 'Material Details',
  //     url: '/upnext-receipts?material_status=veh_ver_approved',
  //     icon: MaterialDetails
  //   },
  //   {
  //     title: 'Batch Details',
  //     url: '/upnext-receipts?material_status=inv_ver_approved',
  //     icon: BatchDetails
  //   },
  //   {
  //     title: 'Damage Report',
  //     url: '/upnext-receipts?material_status=inv_ver_approved',
  //     icon: DamageReport
  //   },
  //   {
  //     title: 'Weighing Report',
  //     url: '/upnext-receipts?material_status=inv_ver_approved',
  //     icon: WeighingReport
  //   },
  //   {
  //     title: 'Generate MRN',
  //     url: '/upnext-receipts?material_status=inv_ver_approved',
  //     icon: GenerateMRN
  //   }
  // ];
  // console.log(cards);
  return (
    <>
      {/* <Row gutter={24}> */}
      {/* {cards?.map((items, index) => {
          return (
            <>
              <Col
                xs={12}
                xl={4}
                md={6}
                sm={6}
                span={4}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Card
                  hoverable
                  className={styles.cardbody}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = items.url;
                  }}
                >
                  <Space direction="vertical">
                    <Image
                      src={items.icon}
                      preview={false}
                      // style={{ height: '200px' }}
                      className={styles.stickers}
                    />
                    <span>{items.title}</span>
                  </Space>
                </Card>
                <br />
              </Col>
            </>
          );
        })} */}
      {/* </Row> */}
      <Card
        className={`${styles.cardStyles_approver}`}
        title="Pending Receipts"
        extra={
          <CustomButton onClick={() => history.push('/create')} type="primary">
            Create MRN
          </CustomButton>
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={getAllMaterilLoading}
            columns={columns}
            dataSource={allMaterials}
            pagination={{
              size: 'small',
              showSizeChanger: false,
              total: pagination?.total_records || 0,
              onChange: (page) => {
                setPage(page);
              }
            }}
            // className={styles.scrolling}
            scroll={{ x: 'auto' }}
          />
        </div>
      </Card>
    </>
  );
};

export default PendingListPage;
