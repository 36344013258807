import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  freecontainers,
  updatebin,
  getcontainerbyid,
  bin_positions,
  getBatch
} from './api';

const initialState = {
  allFreeContainers: [],
  pagination: '',
  getAllFreeContainerLoading: ''
};
const actions = {
  GET_ALL_FREE_CONTAINERS: 'bins/GET_ALL_FREE_CONTAINERS',
  UPDATE_BIN: 'bins/UPDATE_BIN',
  GET_CONTAINERS: 'bins/GET_CONTAINERS',
  GET_BIN_CONTAINERS: 'bins/GET_BIN_CONTAINERS',
  BIN_POSITIONS: 'bins/BIN_POSITIONS',
  DELETE_BIN_CONTAINERS: 'bins/DELETE_BIN_CONTAINERS',
  GET_BATCH_DETAILS: 'bins/GET_BATCH_DETAILS',
  GET_ALL_BIN_LIST: 'bins/GET_ALL_BIN_LIST',
  GENERATE_BIN: 'bins/GENERATE_BIN',
  CREATE_NEW_BIN: 'bins/CREATE_NEW_BIN'
};

export const getbinContainers = createAsyncThunk(
  actions.GET_BIN_CONTAINERS,
  async (payload) => {
    const response = await freecontainers('get-details', payload);
    return response;
  }
);

export const getAllFreeContainers = createAsyncThunk(
  actions.GET_ALL_FREE_CONTAINERS,
  async (payload) => {
    const response = await freecontainers('all-free-containers', payload);
    return response;
  }
);

export const updatebinposition = createAsyncThunk(
  actions.UPDATE_BIN,
  async (payload) => {
    const response = await updatebin('create', payload);
    return response;
  }
);

export const getContainers = createAsyncThunk(
  actions.GET_CONTAINERS,
  async (payload) => {
    const response = await getcontainerbyid('get', payload);
    return response;
  }
);

export const getAllBinPositions = createAsyncThunk(
  actions.BIN_POSITIONS,
  async (payload) => {
    const response = await bin_positions('bin_location', payload);
    return response;
  }
);

export const deletebinContainers = createAsyncThunk(
  actions.DELETE_BIN_CONTAINERS,
  async (payload) => {
    const response = await updatebin('remove_bin_location', payload);
    return response;
  }
);

export const getBatchDetails = createAsyncThunk(
  actions.GET_BATCH_DETAILS,
  async (payload) => {
    const response = await getBatch('get-all', payload);
    return response;
  }
);

export const getAllBinList = createAsyncThunk(
  actions.GET_ALL_BIN_LIST,
  async (payload) => {
    const response = await bin_positions('get_bins', payload);
    return response;
  }
);

export const generateBin = createAsyncThunk(
  actions.GENERATE_BIN,
  async (payload) => {
    const response = await updatebin('bin_label', payload);
    return response;
  }
);

export const createNewBin = createAsyncThunk(
  actions.CREATE_NEW_BIN,
  async (payload) => {
    const response = await updatebin('create_bin_master', payload);
    return response;
  }
);

export const binSlice = createSlice({
  name: 'bins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFreeContainers.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getAllFreeContainers.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          state.allFreeContainers = data;
          state.pagination = pagination || null;
        } else {
          state.allFreeContainers = [];
        }
      })
      .addCase(getAllFreeContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });
    builder
      .addCase(updatebinposition.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(updatebinposition.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updatebinposition.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getContainers.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getContainers.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getbinContainers.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getbinContainers.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getbinContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllBinPositions.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getAllBinPositions.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllBinPositions.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deletebinContainers.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(deletebinContainers.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deletebinContainers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getBatchDetails.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getBatchDetails.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getBatchDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllBinList.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(getAllBinList.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllBinList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(generateBin.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(generateBin.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(generateBin.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createNewBin.pending, (state) => {
        state.getAllFreeContainerLoading = true;
      })
      .addCase(createNewBin.fulfilled, (state, action) => {
        state.getAllFreeContainerLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createNewBin.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllFreeContainerLoading = false;

        message.error(msg);
      });
  }
});
export default binSlice.reducer;
