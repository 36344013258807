import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  batch,
  createbatch,
  updatebatch,
  createRemovedStock,
  updateRemovedStock,
  getRemovedStock,
  approveRemovedStock,
  rejectRemovedStock
} from './api';

const initialState = {
  allBatches: []
};

const actions = {
  GET_ALL_BATCHES: 'issuance/GET_ALL_BATCHES',
  CREATE_ISSUANCE: 'issuance/CREATE_ISSUANCE',
  BATCH_UPDATE: 'issuance/BATCH_UPDATE',
  GET_ALL_REMOVEDSTOCK: 'issuance/GET_ALL_REMOVEDSTOCK',
  CREATE_ALL_REMOVEDSTOCK: 'issuance/CREATE_ALL_REMOVEDSTOCK',
  REMOVEDSTOCK_UPDATE: 'issuance/REMOVEDSTOCK_UPDATE',
  APPROVE_REMOVEDSTOCK: 'issuance/APPROVE_REMOVEDSTOCK',
  REJECT_REMOVEDSTOCK: 'issuance/REJECT_REMOVEDSTOCK'
};

export const getAllBatcheslist = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await batch('all-stocks', payload);

    return response;
  }
);

export const getAllRemovedStock = createAsyncThunk(
  actions.GET_ALL_REMOVEDSTOCK,
  async (payload) => {
    const response = await getRemovedStock('list', payload);

    return response;
  }
);

export const approveAllRemovedStock = createAsyncThunk(
  actions.APPROVE_REMOVEDSTOCK,
  async (payload) => {
    const response = await approveRemovedStock('qa_create', payload);

    return response;
  }
);

export const rejectAllRemovedStock = createAsyncThunk(
  actions.REJECT_REMOVEDSTOCK,
  async (payload) => {
    const response = await rejectRemovedStock('qa_create', payload);

    return response;
  }
);

export const createStockIssuance = createAsyncThunk(
  actions.CREATE_ISSUANCE,
  async (payload) => {
    const response = await createbatch('create-stock-issuance', payload);

    return response;
  }
);

export const createAllRemovedStock = createAsyncThunk(
  actions.CREATE_ALL_REMOVEDSTOCK,
  async (payload) => {
    const response = await createRemovedStock('create', payload);

    return response;
  }
);

export const batchUpdate = createAsyncThunk(
  actions.BATCH_UPDATE,
  async (payload) => {
    const response = await updatebatch('batch_update', payload);

    return response;
  }
);

export const RemovedStockUpdate = createAsyncThunk(
  actions.REMOVEDSTOCK_UPDATE,
  async (payload) => {
    const response = await updateRemovedStock('create', payload);

    return response;
  }
);

export const issuanceSlice = createSlice({
  name: 'issuance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBatcheslist.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllBatcheslist.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          console.log(data);
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllBatcheslist.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllRemovedStock.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllRemovedStock.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, data, pagination } = action.payload;
        if (success) {
          console.log(data);
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          state.allMaterials = [];
        }
      })
      .addCase(getAllRemovedStock.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createStockIssuance.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createStockIssuance.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createStockIssuance.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(approveAllRemovedStock.pending, (state) => {
        state.getAllMaterilLoading = true;
      })

      .addCase(approveAllRemovedStock.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          console.log(data);
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          message.error(msg);
          state.allMaterials = [];
        }
      })
      .addCase(approveAllRemovedStock.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(rejectAllRemovedStock.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(rejectAllRemovedStock.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          console.log(data);
          state.allBatches = data;
          state.pagination = pagination || null;
        } else {
          message.error(msg);
          state.allMaterials = [];
        }
      })
      .addCase(rejectAllRemovedStock.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createAllRemovedStock.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createAllRemovedStock.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createAllRemovedStock.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(batchUpdate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(batchUpdate.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(batchUpdate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(RemovedStockUpdate.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(RemovedStockUpdate.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(RemovedStockUpdate.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default issuanceSlice.reducer;
