import apiClient from 'utilities/apiClient';

export const freecontainers = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.freeContainers}/${url}`,
    payload,
    true
  );
};
export const updatebin = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.freeContainers}/${url}`,
    payload,
    true
  );
};
export const getcontainerbyid = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.freeContainers}/${url}`,
    payload,
    true
  );
};

export const bin_positions = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.freeContainers}/${url}`,
    payload,
    true
  );
};

export const getBatch = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.batch}/${url}`, payload, true);
};
