import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { create_dms, get_batches, create_file_dms } from './api';

const initialState = {
  loading: false
};

const actions = {
  CREATE_DMS: 'createDms/CREATE_DMS',
  GET_ALL_BATCHES: 'getAllBatches/GET_ALL_BATCHES',
  CREATE_FILE_DMS: 'createFileDms/CREATE_FILE_DMS'
};

export const createDms = createAsyncThunk(
  actions.CREATE_DMS,
  async (payload) => {
    const response = await create_dms('reprint_bmr', payload);
    return response;
  }
);

export const getAllBatches = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await get_batches('dms_document_list', payload);
    return response;
  }
);

export const createFileDms = createAsyncThunk(
  actions.CREATE_FILE_DMS,
  async (payload) => {
    const response = await create_file_dms('reprint_bmr', payload);
    return response;
  }
);

export const dmsSlice = createSlice({
  name: 'dms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDms.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createDms.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createDms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createFileDms.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createFileDms.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createFileDms.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllBatches.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllBatches.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
  }
});
export default dmsSlice.reducer;
