import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  // Image,
  Tag,
  message,
  Card
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
// import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { document_qc } from '../../redux/slice';
import { getDocsbyId } from '@/features/material/redux/slice';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;

const DocMissingPage = ({ loading }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { material } = useSelector((state) => state.material);
  // const [truckShow, setTruckShow] = useState(false);
  const [materials, setMaterials] = useState({});
  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterials({ ...materials, [name]: value });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  console.log(material);
  const onFinish = (values) => {
    // setTruckShow(true);
    values['document_id'] = params.id;
    dispatch(document_qc(values)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/qc-list?qc=doc_ver_pending');
      }
    });
  };

  // const handleStandardCancel = () => {
  //   history.push('/dashboard');
  // };
  useEffect(() => {
    handleGetDocsbyId();
  }, []);

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload));
  };

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num')
    });
  }, []);

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card title="DOCUMENT VERIFICATION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Is the Material meant for Graviti?
              </p>
              <p className={styles.customValue}>
                {material?.for_graviti == true
                  ? 'Yes'
                  : material?.for_graviti == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Invoice/DC/BOE Number Available?
              </p>
              <p className={styles.customValue}>
                {material?.invoice_dc_boe_available == true
                  ? 'Yes'
                  : material?.invoice_dc_boe_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Invoice/DC/BOE Number</p>
              <p className={styles.customValue}>
                {material?.invoice_number || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Purchase Order Available?</p>
              <p className={styles.customValue}>
                {material?.po_available == true
                  ? 'Yes'
                  : material?.po_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>COA/COC, MSDS Available?</p>
              <p className={styles.customValue}>
                {material?.coa_coc_msda_available == true
                  ? 'Yes'
                  : material?.coa_coc_msda_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Vendor Status</p>
              <p className={styles.customValue}>
                {material?.material_vendor_status?.toUpperCase() || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list
              </p>
              <p className={styles.customValue}>
                {material?.manufacture_site_address_matching == true
                  ? 'Yes'
                  : material?.manufacture_site_address_matching == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Document Verification Files</p>
              <p className={styles.customValue}>
                {material?.doc_verify_uploads?.length
                  ? material?.doc_verify_uploads.map((file, index) => (
                      <Tag key={index}>
                        <a
                          href={file.doc_url}
                          target="_blank"
                          key={index}
                          rel="noreferrer"
                        >
                          {file.doc_name}
                        </a>
                      </Tag>
                    ))
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.doc_ver_comments || 'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card title="VEHICLE STORAGE AREA" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vehicle Cleanliness</p>

              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_cleanliness == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.vehicle_cleanliness == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Storage In Enclosed Condition
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_enclose == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_enclose == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Storage Condition Available</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_conditions == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_conditions == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_storage_comments ||
                  'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card title="MATERIAL VERIFICATION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Data logger received along with consignment
              </p>

              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.data_logger_received == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.data_logger_received ==
                    false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vendor status label defaced</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vendor_status_label_defaced ==
                true
                  ? 'Yes'
                  : material?.vehicle_storage_area
                      ?.vendor_status_label_defaced == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Dedusting Status</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.material_dedusting_status ||
                  'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Seal integrity of the containers
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.container_seal_integrity ||
                  'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.material_ver_comments || 'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <Card
          title="APPROVAL FOR MISSING DOCUMENT"
          className={styles.CustomPanel}
        >
          <Row>
            <Col xs={24} xl={6} span={6}>
              <FormItem
                label="Invoice Number"
                name="invoice_no"
                rules={[
                  {
                    required: true,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Invoice number"
                  defaultValue={localStorage.getItem('inv_num')}
                  disabled
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Proceed further without PO/Documents"
                name="proceed_without_po_document"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  onChange={handleCapacity}
                  className={styles.customRadio}
                  name="proceed_without_po_document"
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col xs={24} xl={24} span={24}>
              <FormItem
                label="Comments"
                name="comments_on_documents"
                rules={[
                  {
                    required: false,
                    message: 'Enter comments'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  placeholder="Please enter comments"
                  name="comments"
                  // onChange={(e) => handleComments(index, e)}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Approve
            </CustomButton>
          </Space>
        </FormItem>
        {/* </Space> */}
      </Form>
    </Layout>
  );
};
export default DocMissingPage;
