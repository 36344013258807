import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  DatePicker,
  Select,
  Radio,
  message,
  Spin,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '@/common/Content/CustomTable';
// import LocalStorage from 'utilities/localStorage';
import {
  getAllReports,
  getFailedLoginReports,
  DownloadAuditReport
} from '../../redux/slice';
import Layout from '@/common/Layout';
import { DownloadOutlined } from '@ant-design/icons';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
//import { handleGetAllMaterialsList}
// import LocalStorage from 'utilities/localStorage';

const { RangePicker } = DatePicker;
// const dateFormat = 'YYYY-MM-DD';

const ViewAuditTrailsPage = () => {
  const dispatch = useDispatch();
  const { allMaterials } = useSelector((state) => state.stocks);
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [reportData, setReportData] = useState([]);
  const [failedLogData, setFailedLogData] = useState([]);
  const [type, setType] = useState();
  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [fromdate, setFromdate] = useState();
  const [todate, setTodate] = useState();
  const [reporttype, setReporttype] = useState();
  const [search, setSearch] = useState('');
  const [batches, setBatches] = useState([]);
  // const [code, setCode] = useState('');

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const onFinish = (values) => {
    console.log(values);
    // message.error('Something went wrong. We are looking on it!');
    // form.resetFields();
    // setDownloadReports(true);
    setType(values.report);
    const fromDate = values.fromDate
      ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';
    setFromdate(fromDate);
    setTodate(toDate);
    setReporttype(values.report);
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      log_type: values.report,
      batch_details_id: values.inhouse_batch_id ? values.inhouse_batch_id : '',
      page
    };
    type == 'failed_login'
      ? dispatch(getFailedLoginReports(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            setShowTable(true);
            setFailedLogData(response?.payload?.data);
            setPagination(response?.payload?.pagination);
          }
        })
      : dispatch(getAllReports(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            setShowTable(true);
            setReportData(response?.payload?.data);
            setPagination(response?.payload?.pagination);
          }
        });
  };

  console.log(batches);
  const downloadReport = () => {
    const fromDate = fromdate || '';
    const toDate = todate || '';
    const reportType = reporttype || '';

    const payload = {
      from_date: fromDate,
      to_date: toDate,
      log_type: reportType,
      batch_details_id: batches[0]?.id
    };
    dispatch(DownloadAuditReport(payload)).then((response) => {
      console.log(response);
      window.open(response?.payload?.data, '_blank');
      message.success('Report Downloaded successfully');
      form.resetFields();
    });
    // const user = LocalStorage.getItem('Gravity_user');

    // const authToken = user?.token;
    // fetch(
    //   `${process.env.REACT_APP_API_URL}/audit_log/audit_log_download?from_date=${fromDate}&to_date=${toDate}&log_type=${reportType}`,
    //   {
    //     method: 'GET'
    // headers: {
    //   'Content-Type': 'application/csv',
    //   Authorization: `Bearer ${authToken}`
    // }
    //   }
    // ).then((response) =>
    // response.blob())
    // .then((blob) =>
    // {
    //   console.log(response?.url);
    //   window.open(response?.url, '_blank');
    // console.log(blob);
    // const url = window.URL.createObjectURL(new Blob([blob]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `Report.csv`);
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
    // message.success('Report Downloaded successfully');
    // form.resetFields();
    // setDownloadReports(false);
    // }
    // );
  };
  useEffect(() => {
    const payload = {
      page,
      log_type: type,
      from_date: fromdate,
      to_date: todate
    };
    type == 'failed_login'
      ? dispatch(getFailedLoginReports(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            setShowTable(true);
            setFailedLogData(response?.payload?.data);
            setPagination(response?.payload?.pagination);
          }
        })
      : dispatch(getAllReports(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            setShowTable(true);
            setReportData(response?.payload?.data);
            setPagination(response?.payload?.pagination);
          }
        });
  }, [page]);

  const logcolumns = [
    {
      title: 'User ID',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return moment(text).format('DD MMM YYYY') || 'NA';
      }
    },
    {
      title: 'Failed On',
      dataIndex: 'updatde_at',
      key: 'updated_at',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return moment(text).format('DD MMM YYYY') || 'NA';
      }
    }
  ];

  const matstatuscolumns = [
    {
      title: 'Material ID',
      dataIndex: 'material_id',
      key: 'material_id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.material?.code || 'NA';
      }
    },
    {
      title: 'Material Status',
      dataIndex: 'material_status',
      key: 'material_status',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Changed By',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.User?.employee_id || 'NA';
      }
    },
    {
      title: 'Changed On',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          moment(record?.updated_date).format('DD MMM YYYY (hh:mm A)') || 'NA'
        );
      }
    }
  ];
  const venstatuscolumns = [
    {
      title: 'Material ID',
      dataIndex: 'material_id',
      key: 'material_id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.material?.code || 'NA';
      }
    },
    {
      title: 'Vendor Status',
      dataIndex: 'vendor_status',
      key: 'vendor_status',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Changed By',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.User?.employee_id || 'NA';
      }
    },
    {
      title: 'Changed On',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          moment(record?.updated_date).format('DD MMM YYYY (hh:mm A)') || 'NA'
        );
      }
    }
  ];

  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return text || record?.change_field || 'NA';
      }
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return text || 'NA';
      }
    },
    {
      title: 'Changed By',
      dataIndex: 'change_by',
      key: 'change_by',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return type == 'user_log'
          ? text || 'NA'
          : record?.User?.employee_id || 'NA';
      }
    },
    {
      title: 'Changed At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '100px',
      render: (text) => {
        return moment(text).format('DD MMM YYYY (hh:mm A)') || 'NA';
      }
    }
  ];
  const login_logout_columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      ellipsis: true,
      width: '100px'
      // render: (text, record) => {
      //   return text || record?.change_field || 'NA';
      // }
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
      ellipsis: true,
      width: '100px'
      // render: (text) => {
      //   return text || 'NA';
      // }
    },
    {
      title: 'Login / Logout',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: '100px'
      // render: (text) => {
      //   return text || 'NA';
      // }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: '100px'
      // render: (text, record) => {
      //   return type == 'user_log'
      //     ? text || 'NA'
      //     : record?.User?.employee_id || 'NA';
      // }
    }
  ];

  const dispensing_deleted_transactions_columns = [
    {
      title: 'Product Batch Number',
      dataIndex: 'product_batch_number',
      key: 'product_batch_number',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.dispensing_request?.product_batch_number;
      }
    },
    {
      title: 'Inhouse Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.batch_detail?.inhouse_batch_number;
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.batch_detail?.received_quantity || 'NA';
      }
    },
    // {
    //   title: 'Qty Returned',
    //   dataIndex: 'qty_returned',
    //   key: 'qty_returned',
    //   ellipsis: true,
    //   width: '100px',
    //   render: (text, record) => {
    //     return record?.returned_qty || 'NA';
    //   }
    // },
    {
      title: 'WH Remarks',
      dataIndex: 'wh_remarks',
      key: 'wh_remarks',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.wh_comments || 'NA';
      }
    },
    {
      title: 'Deleted by',
      dataIndex: 'deleted_by',
      key: 'deleted_by',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.qa_approver || 'NA';
      }
    },
    {
      title: 'Deleted at',
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      ellipsis: true,
      width: '20px',
      render: (text, record) => {
        return record?.material?.updatedAt;
      }
    }
  ];

  const ebmr_master_creation_log_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.User?.id;
      }
    },
    {
      title: 'Product Creation At ',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.User?.createdAt;
      }
    },
    {
      title: 'Process Group Name',
      dataIndex: 'process_group_name',
      key: 'process_group_name',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.User?.process_group_name || 'NA';
      }
    }
  ];

  const products_log_columns = [
    {
      title: 'Batch Size',
      dataIndex: 'batch_size',
      key: 'batch_size',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.batch_size || 'NA';
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'batch_size',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.name;
      }
    },
    {
      title: 'BMR BPR Code',
      dataIndex: 'bmr_bpr_code',
      key: 'bmr_bpr_code',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.bmr_bpr_code;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_At',
      key: 'created_At',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.createdAt;
      }
    }
  ];

  const trf_generation_log_columns = [
    {
      title: 'Sample Quantity',
      dataIndex: 'sample_quantity',
      key: 'sample_quantity',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.sample_quantity || 'NA';
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.createdAt;
      }
    },
    {
      title: 'InHouse Batch-No.',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.batch_detail?.inhouse_batch_number;
      }
    },
    {
      title: 'Invoice Quantity',
      dataIndex: 'invoice_quantity',
      key: 'invoice_quantity',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.batch_detail?.invoice_quantity;
      }
    }
  ];

  const trf_modification_log_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.id || 'NA';
      }
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.User?.first_name;
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.User?.last_name || 'NA';
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.User?.createdAt;
      }
    }
  ];

  const ebmr_config_log_columns = [
    {
      title: 'Batch Size',
      dataIndex: 'batch_size',
      key: 'batch_size',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.batch_size || 'NA';
      }
    },
    {
      title: 'BMR BPR CODE',
      dataIndex: 'bmr_bpr_code',
      key: 'bmr_bpr_code',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.bmr_bpr_code || 'NA';
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.name;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.createdAt;
      }
    }
  ];

  //For Inhouse-Batch-Status-Log:

  const specificBatchColumns = [
    {
      title: 'InHouse Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.batch_detail?.inhouse_batch_number;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return <Tag> {record?.batch_detail?.status || 'NA'}</Tag>;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return moment(record?.batch_detail?.createdAt).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Created By',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.User?.employee_id;
      }
    }
  ];

  const dateRangeColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.id;
      }
    },
    {
      title: 'Invoice Quantity',
      dataIndex: 'invoice_quantity',
      key: 'invoice_quantity',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.batch_detail?.invoice_quantity || 'NA';
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.batch_detail?.createdAt;
      }
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.User?.first_name;
      }
    }
  ];

  const vendor_creation_log_columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return record?.createdAt;
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.id;
      }
    },
    {
      title: 'Material Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.material?.name || 'NA';
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return record?.updatedAt;
      }
    }
  ];
  const removed_stock_status_logs_columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        return moment(record?.createdAt).format('YYYY-MM-DD');
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return record?.id;
      }
    },
    {
      title: 'Inhouse Batch Number',
      dataIndex: 'inhouse_batch_number',
      key: 'inhouse_batch_number',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return (
          record?.removed_stock?.batch_detail?.inhouse_batch_number || 'NA'
        );
      }
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        return moment(record?.updatedAt).format('YYYY-MM-DD');
      }
    }
  ];

  const [batchOrDateRange, setBatchOrDateRange] = useState(null); // State to track radio button selection

  const selectedColumns =
    batchOrDateRange === 'specific_batch'
      ? specificBatchColumns
      : specificBatchColumns;
  console.log(dateRangeColumns);
  const [selectedOption, setSelectedOption] = useState('');
  // const [allMaterials, setAllMaterials] = useState([]);
  // const [batches1, setBatches1] = useState([]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const onChange = (value, type) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );

    let payload = { search: material[0].code };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setBatches(response?.payload?.data[0]?.batch_details);
        // dispatch(getAllMaterialslist());
      }
    });
    form.setFieldsValue({ supplier_id: null });
    type == 'id'
      ? form.setFieldsValue({ material_name: value })
      : form.setFieldsValue({ material_id: value });
    // setCode(material[0]);
  };

  const onSearch = (value) => {
    setSearch(value);
    console.log(value);
  };

  const onChangeBatch = (value) => {
    const batches_details =
      value == 'all'
        ? allMaterials[0]?.batch_details
        : batches.filter((batches) => batches.id === value);
    setBatches(batches_details);
  };

  const onSearchBatch = (value) => {
    const batches_details = batches.inhouse_batch_number
      .toLowerCase()
      .includes(value.toLowerCase());
    setBatches(batches_details);
  };

  // const material_columns = [
  //   {
  //     title: 'Inhouse Batch #',
  //     dataIndex: 'inhouse_batch_number',
  //     key: 'inhouse_batch_number',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text, record) => {
  //       return record?.status == 'Approved' && user?.role == 'user' ? (
  //         // &&
  //         // user?.level == 3
  //         <a href={`stock-card-issuance/${record.id}`}>{text}</a>
  //       ) : (
  //         // text
  //         // text;
  //         <a href={`stock-card-issuance/${record.id}`}>{text}</a>
  //       );
  //     }
  //   },
  //   {
  //     title: 'MRN #',
  //     dataIndex: 'mrn_number',
  //     key: 'mrn_number',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text, record) => {
  //       return record?.mrn_number || 'NA';
  //     }
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     key: 'status',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (text) => {
  //       return text == 'Approved' ? (
  //         <Tag color="#4CAF50">{text?.toUpperCase()}</Tag>
  //       ) : text == 'Quarantine' ? (
  //         <Tag color="#FF9800">{text?.toUpperCase()}</Tag>
  //       ) : text == 'Expired' ? (
  //         <Tag color="#939089d9">{text?.toUpperCase()}</Tag>
  //       ) : text == 'Rejected' ? (
  //         <Tag color="#fa2a3e">{text?.toUpperCase()}</Tag>
  //       ) : text === 'Under Sampling' ? (
  //         <Tag color="#17a2b8">{text?.toUpperCase()}</Tag>
  //       ) : text === 'Under Test' ? (
  //         <Tag color="#343a40">{text?.toUpperCase()}</Tag>
  //       ) : text === 'OnHold' ? (
  //         <Tag color="#781720">{text?.toUpperCase()}</Tag>
  //       ) : (
  //         <Tag color="#4CAF50">{text?.toUpperCase()}</Tag>
  //       );
  //     }
  //   },

  //   {
  //     title: 'Closing Qty',
  //     dataIndex: 'closing_quantity',
  //     key: 'closing_quantity',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       // var issued_qty = 0;
  //       // record?.stock_card_issuances?.map((item) => {
  //       //   issued_qty += parseFloat(item?.quantity_issued || 0);
  //       // });
  //       console.log(
  //         record?.stock_card_issuances?.[
  //           record?.stock_card_issuances?.length - 1
  //         ]?.closing_balance,
  //         'issued_qty'
  //       );
  //       return (
  //         `${
  //           record?.stock_card_issuances?.[
  //             record?.stock_card_issuances?.length - 1
  //           ]?.closing_balance
  //         }  ${code?.uom}` || 'NA'

  //         // Quantity Received (-) Sampling Quantity (+ or -) Stock Reconciliation Qty (-) Wastage Material Qty (+) Returned Qty
  //         // // `${
  //         // //   parseFloat(record?.received_quantity || 0) -
  //         // //   parseFloat(record?.sampling?.[0]?.total_value || 0) +
  //         // //   parseFloat(
  //         // //     record?.stock_reconsiliation?.[0]?.total_reconcile || 0
  //         // //   ) -
  //         // //   parseFloat(record?.wastage_quantity?.[0]?.total_wastage || 0) +
  //         // //   parseFloat(
  //         // //     record?.stock_card_issuances?.[0]?.quantity_returned || 0
  //         // //   )
  //         // } ${code?.uom}`
  //       );
  //     }
  //   },
  //   {
  //     title: 'Mfg Batch/Lot #',
  //     dataIndex: 'mfg_batch_lot_no',
  //     key: 'mfg_batch_lot_no',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Mfg Date',
  //     dataIndex: 'mfg_date',
  //     key: 'mfg_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.mfg_date
  //         ? moment(record?.mfg_date).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Mfg Expiry Date',
  //     dataIndex: 'mfg_expiry_date',
  //     key: 'mfg_expiry_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.mfg_expiry_date
  //         ? moment(record?.mfg_expiry_date).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Mfg Re-test Date',
  //     dataIndex: 'mfg_retest_date',
  //     key: 'mfg_retest_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.mfg_retest_date
  //         ? moment(record?.mfg_retest_date).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Inhouse Expiry Date',
  //     dataIndex: 'in_house_expiry_date',
  //     key: 'in_house_expiry_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.in_house_expiry_date
  //         ? moment(record?.in_house_expiry_date).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Inhouse Retest Date',
  //     dataIndex: 'inhouse_retest_date',
  //     key: 'inhouse_retest_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.inhouse_retest_date
  //         ? moment(record?.inhouse_retest_date).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Received Qty',
  //     dataIndex: 'quantity_received',
  //     key: 'quantity_received',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         `${parseFloat(record?.received_quantity || 0).toFixed(3)}  ${
  //           code?.uom
  //         }` || 'NA'
  //       );
  //     }
  //   },
  //   {
  //     title: 'Sampling Qty',
  //     dataIndex: 'sampling_quantity',
  //     key: 'sampling_quantity',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         `${parseFloat(record?.sampling?.[0]?.total_value || 0).toFixed(3)}  ${
  //           code?.uom
  //         }` || 'NA'
  //       );
  //     }
  //   },

  //   {
  //     title: 'Approved Qty',
  //     dataIndex: 'approved_quantity',
  //     key: 'approved_quantity',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         // `${
  //         //   record?.stock_card_issuances?.length
  //         //     ? parseFloat(record?.stock_card_issuances?.[0]?.closing_balance)
  //         //     : parseFloat(record?.received_quantity)
  //         // }  ${code?.uom}` || 'NA'

  //         // Quantity Received (-) Sampling Quantity (+ or -) Stock Reconciliation Qty (-) Wastage Material Qty (+) Returned Qty
  //         record?.status === 'Quarantine'
  //           ? 0
  //           : `${
  //               (
  //                 parseFloat(record?.received_quantity || 0) -
  //                 parseFloat(record?.sampling?.[0]?.total_value || 0)
  //               ).toFixed(3)
  //               //  +
  //               // parseFloat(
  //               //   record?.stock_reconsiliation?.[0]?.total_reconcile || 0
  //               // ) -
  //               // parseFloat(record?.wastage_quantity?.[0]?.total_wastage || 0) +
  //               // parseFloat(
  //               //   record?.stock_card_issuances?.[0]?.quantity_returned || 0
  //               // )
  //             } ${code?.uom}`
  //       );
  //     }
  //   },
  //   {
  //     title: 'Issued Qty',
  //     dataIndex: 'issued_quantity',
  //     key: 'issued_quantity',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       var issued_qty = 0;
  //       record?.stock_card_issuances?.map((item) => {
  //         issued_qty += parseFloat(item?.quantity_issued || 0);
  //       });

  //       return `${parseFloat(issued_qty || 0).toFixed(3)} ${code?.uom}`;
  //     }
  //   },
  //   {
  //     title: 'Stock Reconciliation Qty',
  //     dataIndex: 'stock_reconciliation_qty',
  //     key: 'stock_reconciliation_qty',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         `${parseFloat(
  //           record?.stock_reconsiliation?.[0]?.total_reconcile || 0
  //         ).toFixed(3)}  ${code?.uom}` || 'NA'
  //       );
  //     }
  //   },
  //   {
  //     title: 'Wastage Material Qty',
  //     dataIndex: 'wastage_material_qty',
  //     key: 'wastage_material_qty',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         `${parseFloat(
  //           record?.wastage_quantity?.[0]?.total_wastage || 0
  //         ).toFixed(3)}  ${code?.uom}` || 'NA'
  //       );
  //     }
  //   },
  //   {
  //     title: 'Returned Qty',
  //     dataIndex: 'returned_qty',
  //     key: 'returned_qty',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return (
  //         `${parseFloat(record?.stock_returned_qty || 0).toFixed(3)}  ${
  //           code?.uom
  //         }` || 'NA'
  //       );
  //     }
  //   },

  //   {
  //     title: 'AR #',
  //     dataIndex: 'ar_number',
  //     key: 'ar_number',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.sampling_histories?.length
  //         ? record?.sampling_histories[0]?.ar_number
  //         : 'NA' || 'NA';
  //     }
  //   },
  //   {
  //     title: 'Date of Receipt',
  //     dataIndex: 'received_date',
  //     key: 'received_date',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.sampling_histories?.length
  //         ? record?.sampling_histories[0]?.received_date
  //           ? moment(record?.sampling_histories[0]?.received_date).format(
  //               'DD MMM YYYY'
  //             )
  //           : 'NA'
  //         : 'NA';
  //     }
  //   },
  //   {
  //     title: 'Received by WH',
  //     dataIndex: 'received_by_wh',
  //     key: 'received_by_wh',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.received_by_wh || 'NA';
  //     }
  //   },
  //   {
  //     title: 'Checked by WH',
  //     dataIndex: 'checked_by_wh',
  //     key: 'checked_by_wh',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.checked_by_wh || 'NA';
  //     }
  //   },
  //   {
  //     title: 'Sampling Done by QC',
  //     dataIndex: 'sampling_done_by_qc',
  //     key: 'sampling_done_by_qc',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.sampling_done_by_qc || 'NA';
  //     }
  //   },
  //   {
  //     title: 'Remarks',
  //     dataIndex: 'remarks',
  //     key: 'remarks',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.remarks || 'NA';
  //     }
  //   }
  // ];

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={'VIEW & DOWNLOAD AUDIT REPORT'}
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Report Name"
                name="report"
                rules={[
                  {
                    required: true,
                    message: 'Select Report'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Please Select Report"
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option value="user_log">User Log Details</option>
                  <option value="mrn_log">MRN Log Details</option>
                  <option value="sampling_log">Sampling Log Details</option>
                  <option value="dispensing_log">Dispensing Log Details</option>
                  <option value="wastage_log">Wastage Log Details</option>
                  <option value="failed_login">Failed Login Log Details</option>
                  <option value="login_logout">
                    User Login & Logout Details
                  </option>
                  <option value="vendor_creation_log">
                    Vendor Creation Log
                  </option>
                  <option value="vendor_modification_log">
                    Vendor Modification Log
                  </option>
                  <option value="inhousebatch_status_log">
                    In House Batch Status Log
                  </option>
                  <option value="ebmr_master_modification_log">
                    E-BMR Master modification Log
                  </option>
                  <option value="products_log">Products Log</option>
                  <option value="ebmr_master_creation_log">
                    E-BMR Master creation Log
                  </option>
                  <option value="trf_generation_log">TRF generation Log</option>
                  <option value="trf_modification_log">
                    TRF Modification Log
                  </option>
                  <option value="ebmr_config_log">
                    E-BMR Configuration Log
                  </option>
                  {/* <option value="">Vendor Data log</option>
                <option value="">In-House Batch Status log</option>*/}
                  <option value="dispensing_deleted_transactions">
                    Dispensing deleted transactions
                  </option>
                  <option value="material_status_logs">
                    Material status logs
                  </option>

                  <option value="material_modification_logs">
                    Material Modification logs
                  </option>
                  <option value="vendor_status_logs">Vendor status logs</option>
                  <option value="removed_stock_status_logs">
                    Removed Stock status logs
                  </option>
                  <option value="removed_stock_modification_logs">
                    Removed Stock Modification logs
                  </option>
                </Select>
              </FormItem>
            </Col>

            {selectedOption === 'inhousebatch_status_log' ? (
              <>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Select Batch or Date Range"
                    name="batchOrDateRange"
                    rules={[
                      {
                        required: true,
                        message: 'Please select an option'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      onChange={(e) => setBatchOrDateRange(e.target.value)}
                    >
                      <Radio value="specific_batch">Specific Batch</Radio>
                      <Radio value="date_range">Date Range</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {batchOrDateRange === 'specific_batch' && (
                  <>
                    <Col xs={24} xl={24} span={24} md={24} sm={24}>
                      <Row gutter={24}>
                        <Col xs={24} xl={12} span={12} md={24} sm={24}>
                          <FormItem
                            label="Material Code"
                            name="material_id"
                            rules={[
                              {
                                required: true,
                                message: 'Select Material Code'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              showSearch
                              placeholder="Select a material code"
                              optionFilterProp="children"
                              onChange={(e) => onChange(e, 'id')}
                              onSearch={onSearch}
                            >
                              {allMaterials ? (
                                allMaterials?.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.code}
                                    </option>
                                  );
                                })
                              ) : (
                                <Spin />
                              )}
                            </Select>
                          </FormItem>
                        </Col>

                        <Col xs={24} xl={6} span={12} md={24} sm={24}>
                          <FormItem
                            label="In House Batch #"
                            name="inhouse_batch_id"
                            rules={[
                              {
                                required: true,
                                message: 'Select In House Batch Number'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Select
                              showSearch
                              placeholder="Select In House Batch Number"
                              optionFilterProp="children"
                              onChange={onChangeBatch}
                              onSearch={onSearchBatch}
                            >
                              {batches?.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.inhouse_batch_number}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}

                {batchOrDateRange === 'date_range' && (
                  <FormItem
                    label="Select start and end date"
                    name="fromDate"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Select date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <RangePicker
                      format={'YYYY-MM-DD'}
                      onChange={(x) => setDateRange(x)}
                      disabledDate={(current) =>
                        current && current.valueOf() > Date.now()
                      }
                      value={dateRange}
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                )}
              </>
            ) : (
              <FormItem
                label="Select start and end date"
                name="fromDate"
                rules={[
                  {
                    required: false,
                    message: 'Enter Select date'
                  }
                ]}
                className={styles.customLable}
              >
                <RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(x) => setDateRange(x)}
                  disabledDate={(current) =>
                    current && current.valueOf() > Date.now()
                  }
                  value={dateRange}
                  style={{ width: '100%' }}
                />
              </FormItem>
            )}
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              View
            </CustomButton>
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
        {showTable ? (
          <Card
            className={`${styles.subpanel}`}
            extra={
              <CustomButton type="primary" ghost onClick={downloadReport}>
                <DownloadOutlined />
              </CustomButton>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={
                  type === 'inhousebatch_status_log'
                    ? selectedColumns
                    : type === 'failed_login'
                    ? logcolumns
                    : type === 'login_logout'
                    ? login_logout_columns
                    : type === 'dispensing_deleted_transactions'
                    ? dispensing_deleted_transactions_columns
                    : type === 'ebmr_master_creation_log'
                    ? ebmr_master_creation_log_columns
                    : type === 'products_log'
                    ? products_log_columns
                    : type === 'trf_generation_log'
                    ? trf_generation_log_columns
                    : type === 'trf_modification_log'
                    ? trf_modification_log_columns
                    : type === 'ebmr_config_log'
                    ? ebmr_config_log_columns
                    : type === 'vendor_creation_log'
                    ? vendor_creation_log_columns
                    : type === 'removed_stock_status_logs'
                    ? removed_stock_status_logs_columns
                    : type === 'material_status_logs'
                    ? matstatuscolumns
                    : type === 'vendor_status_logs'
                    ? venstatuscolumns
                    : columns
                }
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                scroll={{ x: 'auto' }}
                dataSource={
                  type === 'failed_login' ? failedLogData : reportData || [] // Fallback to an empty array if data is undefined
                }
              />
            </div>
          </Card>
        ) : (
          ''
        )}
      </Form>
    </Layout>
  );
};
export default ViewAuditTrailsPage;
