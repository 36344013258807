import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  // Collapse,
  Form,
  Input,
  // InputNumber,
  // DatePicker,
  // Select,
  Radio,
  Space,
  Image,
  message,
  Card,
  Tag,
  Upload,
  Button
  // Slider
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '../images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInvoice,
  getAllSuppliers,
  getAllMaterialslist,
  getDocsbyId,
  getFocusedPOs,
  DeleteAttachment
} from '../../redux/slice';
import { history } from 'app/history';
// import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import VerificationModal from '../VerificationModal';
import { getLoggedInUser, getRoles } from 'utilities/helpers';

const { TextArea } = Input;

const InvoiceDetailsPage = ({ loading }) => {
  const params = useParams();
  const { material } = useSelector((state) => state.material);
  console.log(material);
  const dispatch = useDispatch();

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [form] = Form.useForm();

  const [truckShow, setTruckShow] = useState(false);
  // const [code, setCode] = useState('');
  // const [searchvendor, setSearchvendor] = useState('');
  // const [suppliersdata, setSuppliersdata] = useState([]);
  // const [manufacturer, setManufacturer] = useState();
  // const [selectVendor, setSelectVendor] = useState('');
  // const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [materials, setMaterials] = useState({});
  const [isModal, setIsModal] = useState(false);
  // const [poqty, setPoqty] = useState(0);
  // const [unitRate, setUnitRate] = useState(0);
  const [isDocEdit, setIsDocEdit] = useState(false);
  const [matIsEdit, setMatIsEdit] = useState(false);
  const [fileList, setFileList] = useState(material?.doc_verify_uploads);
  const [newFiles, setNewFiles] = useState([]);
  const [type, setType] = useState('');
  // const [material, setMaterial] = useState({});

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  // const onChange = (value) => {
  //   const material = MaterialsList.filter(
  //     (MaterialsList) => MaterialsList.id === value
  //   );
  //   form.setFieldsValue({ supplier_id: null });
  //   setCode(material[0]);
  //   setSuppliersdata(material[0].suppliers);
  //   setSelectVendor('');
  // };

  // const onChangeSupplier = (value) => {
  //   const manufacturerData = suppliersdata.filter(
  //     (suppliersdata) => suppliersdata.id === value
  //   );
  //   setManufacturer(manufacturerData);
  // };
  // console.log(selectVendor);
  // const onSearch = (value) => {
  //   setSearch(value);
  // };

  // const onSearchvendor = (value) => {
  //   setSearchvendor(value);
  // };

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterials({ ...materials, [name]: value });
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     material_name: code?.name,
  //     // supplier_id: '',
  //     uom: code?.uom,
  //     mfg_name: '',
  //     vendor_status: null
  //   });
  // }, []);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     mfg_name: manufacturer ? manufacturer[0]?.manufacturer_name : '',
  //     vendor_status: manufacturer ? manufacturer[0]?.vendor_status : ''
  //   });
  // }, [manufacturer]);
  useEffect(() => {
    handleGetAllSuppliers();
  }, []);
  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetDocsbyId();
    handleGetFocusPOnumbers();
  }, [isDocEdit, isEdit]);

  useEffect(() => {
    setFileList(material?.doc_verify_uploads);
  }, [material]);

  const handleOnChangePdfUpload = (info) => {
    console.log(info);
    setFileList(info.fileList);
  };

  const handleDelete = (removedTag, id) => {
    if (id !== '') {
      const payload = {
        id: id
      };
      dispatch(DeleteAttachment(payload)).then((res) => {
        if (res?.payload.status) {
          message.success('File deleted successfully');
          handleGetDocsbyId();
        }
      });
    }
    const newTags = fileList.filter((tag) => tag.name !== removedTag);
    setFileList(newTags);
  };

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload));
  };

  const handleGetFocusPOnumbers = () => {
    const payload = {};
    dispatch(getFocusedPOs(payload));
  };

  const onFinish = (values) => {
    values['document_id'] = params.id;
    values['material_status'] = 'inv_ver_approved';
    // values['po_total_amount'] = parseFloat(poqty) * parseFloat(unitRate);
    // setTruckShow(true);

    dispatch(createInvoice(values)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          localStorage.setItem(
            'material_code',
            response?.payload?.data?.material?.code
          );
          localStorage.setItem(
            'material_name',
            response?.payload?.data?.material?.name
          );
          localStorage.setItem(
            'invoice_num',
            response?.payload?.data?.invoice_number
          );
          message.success(response?.payload?.message);
          history.push(`/batch-details/${params.id}`);
        }, 2000);
      } else {
        setTruckShow(false);
      }
    });
  };

  const handleStandardCancel = () => {
    setIsDocEdit(false);
    setIsEdit(false);
    setMatIsEdit(false);
  };
  const handleGetAllMaterialsList = () => {
    let payload = {};
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetAllSuppliers = () => {
    let payload = {};
    dispatch(getAllSuppliers(payload));
  };

  // const handleSeldctVendor = (value) => {
  //   setSelectVendor(value);
  // };

  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num')
    });
  }, []);

  // const hanldeChange = (e) => {
  //   setPoqty(e);
  // };
  // const hanldeChangeUnit = (e) => {
  //   setUnitRate(e);
  // };

  const handleVerification = (type) => {
    if (type === 'document_verification') {
      let newFiles = fileList.filter((file) => !file.id);
      let files = [];
      newFiles.map((data) => {
        files.push(data.originFileObj);
      });
      setNewFiles(files);
    }

    setIsModal(true);
    setType(type);
  };

  const handleEdit = (type) => {
    if (type === 'doc') {
      console.log(material.for_graviti);
      setIsDocEdit(true);
      form.setFieldsValue({
        gravity:
          material?.for_graviti === true
            ? 'Yes'
            : material?.for_graviti === false
            ? 'No'
            : 'NA',
        invoice_dc_boe_available:
          material?.invoice_dc_boe_available == true
            ? 'Yes'
            : material?.invoice_dc_boe_available == false
            ? 'No'
            : 'NA',
        invoice_number: material?.invoice_number,
        po_available:
          material?.po_available == true
            ? 'Yes'
            : material?.po_available == false
            ? 'No'
            : 'NA',
        coa_coc_msda_available:
          material?.coa_coc_msda_available == true
            ? 'Yes'
            : material?.coa_coc_msda_available == false
            ? 'No'
            : 'NA',
        material_vendor_status: material?.material_vendor_status.toLowerCase(),
        manufacture_site_address_matching:
          material?.manufacture_site_address_matching == true
            ? 'Yes'
            : material?.manufacture_site_address_matching == false
            ? 'No'
            : 'NA',
        doc_ver_comments: material?.doc_ver_comments
      });
    } else if (type === 'vehicle') {
      setIsEdit(true);
      form.setFieldsValue({
        vehicle_cleanliness:
          material?.vehicle_storage_area?.vehicle_cleanliness == true
            ? 'Yes'
            : material?.vehicle_storage_area?.vehicle_cleanliness == false
            ? 'No'
            : 'NA',
        storage_enclose:
          material?.vehicle_storage_area?.storage_enclose == true
            ? 'Yes'
            : material?.vehicle_storage_area?.storage_enclose == false
            ? 'No'
            : 'NA',
        storage_conditions:
          material?.vehicle_storage_area?.storage_conditions == true
            ? 'Yes'
            : material?.vehicle_storage_area?.storage_conditions == false
            ? 'No'
            : 'NA'
      });
    } else {
      setMatIsEdit(true);
      form.setFieldsValue({
        data_logger:
          material?.vehicle_storage_area?.data_logger_received == true
            ? 'Yes'
            : material?.vehicle_storage_area?.data_logger_received == false
            ? 'No'
            : 'NA',
        labels_defaced:
          material?.vehicle_storage_area?.vendor_status_label_defaced == true
            ? 'Yes'
            : material?.vehicle_storage_area?.vendor_status_label_defaced ==
              false
            ? 'No'
            : 'NA',
        dedusting_status:
          material?.vehicle_storage_area?.material_dedusting_status || 'NA',
        seal_integrity:
          material?.vehicle_storage_area?.container_seal_integrity || 'NA',
        material_ver_comments:
          material?.vehicle_storage_area?.material_ver_comments == true
            ? 'Yes'
            : material?.vehicle_storage_area?.material_ver_comments == false
            ? 'No'
            : 'NA'
      });
    }
  };
  return (
    // <Layout sider={false}>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Card
        title="DOCUMENT VERIFICATION"
        className={styles.CustomPanel}
        extra={
          user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
            <EditOutlined onClick={() => handleEdit('doc')} />
          ) : (
            ''
          )
        }
      >
        {isDocEdit ? (
          <>
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Is the material meant for graviti?"
                  name="gravity"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="gravity"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>

              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Invoice/DC/BOE Number available"
                  name="invoice_dc_boe_available"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="invoice_dc_boe_available"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Invoice/DC/BOE Number"
                  name="invoice_number"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Invoice number"
                    name="invoice_number"
                    onChange={handleCapacity}
                  />
                </FormItem>
              </Col>

              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Purchase Order available"
                  name="po_available"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="po_available"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="COA/COC, MSDS available"
                  name="coa_coc_msda_available"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="coa_coc_msda_available"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Material Vendor Status"
                  name="material_vendor_status"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="material_vendor_status"
                  >
                    <Radio value="approved">Approved</Radio>
                    <Radio value="prov">Prov Approved</Radio>
                    <Radio value="New">New</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem
                  label="Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list"
                  name="manufacture_site_address_matching"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="manufacture_site_address_matching"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Upload a File"
                  // name="coa_coc_msda_available"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Upload
                    accept=".pdf"
                    fileList={fileList}
                    className={`avatar-uploader ${styles.upload}`}
                    onChange={handleOnChangePdfUpload}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok');
                      }, 0);
                    }}
                    multiple={true}
                    showUploadList={false}
                  >
                    <Button
                      icon={<PaperClipOutlined />}
                      className={styles.upload_button}
                      style={{ width: '100%', borderRadius: '12px' }}
                    >
                      Attach File
                    </Button>
                  </Upload>
                  <br />
                  <br />
                  <Space direction="vertical">
                    {fileList?.length > 0
                      ? fileList.map(({ doc_name, name, id, index }) => (
                          <Tag
                            closable
                            onClose={(e) => {
                              e.preventDefault();
                              handleDelete(doc_name || name, id);
                            }}
                            key={index}
                          >
                            {doc_name || name}
                          </Tag>
                        ))
                      : ''}
                  </Space>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={8}>
                <FormItem
                  label="Comments"
                  name="doc_ver_comments"
                  rules={[
                    {
                      required: false,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea
                    placeholder="Enter comments"
                    name="doc_ver_comments"
                    onChange={handleCapacity}
                  ></TextArea>
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  htmlType="button"
                  onClick={handleStandardCancel}
                  className={styles.border_button}
                >
                  Cancel
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => handleVerification('document_verification')}
                    ghost
                  >
                    Initiate Verification
                  </CustomButton>
                )}
              </Space>
            </FormItem>
          </>
        ) : (
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Is the Material meant for Graviti?
              </p>
              <p className={styles.customValue}>
                {material?.for_graviti == true
                  ? 'Yes'
                  : material?.for_graviti == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Invoice/DC/BOE Number Available?
              </p>
              <p className={styles.customValue}>
                {material?.invoice_dc_boe_available == true
                  ? 'Yes'
                  : material?.invoice_dc_boe_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Invoice/DC/BOE Number</p>
              <p className={styles.customValue}>
                {material?.invoice_number || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Purchase Order Available?</p>
              <p className={styles.customValue}>
                {material?.po_available == true
                  ? 'Yes'
                  : material?.po_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>COA/COC, MSDS Available?</p>
              <p className={styles.customValue}>
                {material?.coa_coc_msda_available == true
                  ? 'Yes'
                  : material?.coa_coc_msda_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Vendor Status</p>
              <p className={styles.customValue}>
                {material?.material_vendor_status?.toUpperCase() || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list
              </p>
              <p className={styles.customValue}>
                {material?.manufacture_site_address_matching == true
                  ? 'Yes'
                  : material?.manufacture_site_address_matching == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Document Verification Files</p>
              <p className={styles.customValue}>
                {material?.doc_verify_uploads?.length
                  ? material?.doc_verify_uploads.map((file, index) => (
                      <Tag key={index}>
                        <a
                          href={file.doc_url}
                          target="_blank"
                          key={index}
                          rel="noreferrer"
                        >
                          {file.doc_name}
                        </a>
                      </Tag>
                    ))
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.doc_ver_comments || 'NA'}
              </p>
            </Col>
          </Row>
        )}
      </Card>
      <br />
      <Card
        title="VEHICLE STORAGE AREA"
        className={styles.CustomPanel}
        extra={
          user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
            <EditOutlined onClick={() => handleEdit('vehicle')} />
          ) : (
            ''
          )
        }
      >
        {isEdit ? (
          <>
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Vehicle cleanliness"
                  name="vehicle_cleanliness"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="vehicle_cleanliness"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Storage in  enclosed condition"
                  name="storage_enclose"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="storage_enclose"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Storage condition available"
                  name="storage_conditions"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="storage_conditions"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={8}>
                <FormItem
                  label="Comments"
                  name="vehicle_storage_comments"
                  rules={[
                    {
                      required: false,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea
                    placeholder="Enter comments"
                    name="vehicle_storage_comments"
                    onChange={handleCapacity}
                  ></TextArea>
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  htmlType="button"
                  onClick={handleStandardCancel}
                  className={styles.border_button}
                >
                  Cancel
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => handleVerification('vehicle_storage')}
                    ghost
                  >
                    Initiate Verification
                  </CustomButton>
                )}
              </Space>
            </FormItem>
          </>
        ) : (
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vehicle Cleanliness</p>

              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_cleanliness == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.vehicle_cleanliness == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Storage In Enclosed Condition
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_enclose == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_enclose == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Storage Condition Available</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_conditions == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_conditions == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_storage_comments ||
                  'NA'}
              </p>
            </Col>
          </Row>
        )}
      </Card>
      <br />
      <Card
        title="MATERIAL VERIFICATION"
        className={styles.CustomPanel}
        extra={
          user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
            <EditOutlined onClick={() => handleEdit('material')} />
          ) : (
            ''
          )
        }
      >
        {matIsEdit ? (
          <>
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Data logger received along with consignment"
                  name="data_logger"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="data_logger"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Vendor status label defaced"
                  name="labels_defaced"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="labels_defaced"
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Material dedusting status"
                  name="dedusting_status"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="dedusting_status"
                  >
                    <Radio value="Yes">Performed</Radio>
                    <Radio value="No">Not performed</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Seal integrity of the containers"
                  name="seal_integrity"
                  rules={[
                    {
                      required: true,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group
                    onChange={handleCapacity}
                    className={styles.customRadio}
                    name="seal_integrity"
                  >
                    <Radio value="Yes">Satisfactory</Radio>
                    <Radio value="No">Not satisfactory</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={24} xl={12} span={8}>
                <FormItem
                  label="Comments"
                  name="material_ver_comments"
                  rules={[
                    {
                      required: false,
                      message: 'Choose Yes/No'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea
                    placeholder="Enter comments"
                    name="material_ver_comments"
                    onChange={handleCapacity}
                  ></TextArea>
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  htmlType="button"
                  onClick={handleStandardCancel}
                  className={styles.border_button}
                >
                  Cancel
                </CustomButton>
                {truckShow ? (
                  <Image
                    src={truck}
                    preview={false}
                    style={{ width: '100px !important', height: '100px' }}
                  />
                ) : (
                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => handleVerification('material_verfiy')}
                    ghost
                  >
                    Initiate Verification
                  </CustomButton>
                )}
              </Space>
            </FormItem>
          </>
        ) : (
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Data logger received along with consignment
              </p>

              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.data_logger_received == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.data_logger_received ==
                    false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vendor status label defaced</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vendor_status_label_defaced ==
                true
                  ? 'Yes'
                  : material?.vehicle_storage_area
                      ?.vendor_status_label_defaced == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Dedusting Status</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.material_dedusting_status ||
                  'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Seal integrity of the containers
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.container_seal_integrity ||
                  'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Comments</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.material_ver_comments || 'NA'}
              </p>
            </Col>
          </Row>
        )}
      </Card>
      <br />
      {/* <Card title="MATERIAL DETAILS" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Material Code"
                name="material_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a material code"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                >
                  {MaterialsList?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.code}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Name"
                name="supplier_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Supplier name'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a supplier name"
                  optionFilterProp="children"
                  onSearch={onSearchvendor}
                  onChange={onChangeSupplier}
                  onSelect={handleSeldctVendor}
                >
                  {suppliersdata?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Name of the Material"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter name of the material" disabled />
              </FormItem>
            </Col>

            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Name"
                name="mfg_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter manufacturer name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter manufacturer Name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Material Vendor Status"
                name="vendor_status"
                rules={[
                  {
                    required: true,
                    message: 'Select vendor status'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select a status"
                  optionFilterProp="children"
                  // onChange={onChange}
                  disabled
                >
                  {Statuses.map((item, index) => {
                    return (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[
                  {
                    required: true,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter UOM" disabled />
              </FormItem>
            </Col>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="No of Batches"
                name="no_of_bacthes_invoice"
                rules={[
                  {
                    required: true,
                    message: 'Enter Number of Batches'
                  }
                ]}
                className={styles.customLable}
              >
                <InputNumber
                  min={1}
                  max={100000}
                  placeholder="Enter Number of Batches"
                  style={{
                    width: '100%'
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br /> */}
      {/* <Card title="PO DETAILS" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="PO Number"
                name="po_number"
                rules={[
                  {
                    required: true,
                    message: 'Enter PO number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter PO number" />
              </FormItem>
            </Col>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="PO Quantity"
                name="po_qty"
                rules={[
                  {
                    required: true,
                    message: 'Enter PO qty'
                  }
                ]}
                className={styles.customLable}
              >
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Enter PO qty"
                  onChange={(e) => hanldeChange(e)}
                />
              </FormItem>
            </Col>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="PO Unit Rate (INR)"
                name="po_unit_rate"
                rules={[
                  {
                    required: true,
                    message: 'Enter PO unit rate'
                  }
                ]}
                className={styles.customLable}
              >
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Enter PO unit rate"
                  onChange={(e) => hanldeChangeUnit(e)}
                />
              </FormItem>
            </Col>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="PO Date"
                name="po_date"
                rules={[
                  {
                    required: false,
                    message: 'Enter PO date'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Date"
                  style={{ width: '100%' }}
                  name="po_date"
                />
              </FormItem>
            </Col>
            <Col xs={12} xl={12} span={12}>
              <FormItem
                label="PO Total Amount"
                // name="po_total_amount"
                rules={[
                  {
                    required: false,
                    message: 'Enter PO Total Amount'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter PO Total Amount"
                  value={parseFloat(poqty) * parseFloat(unitRate)}
                  disabled
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Focus PO #"
                name="po_domestic_id"
                rules={[
                  {
                    required: false,
                    message: 'Select a Focus PO #'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a Focus PO #"
                  optionFilterProp="children"
                  // onChange={onChange}
                  onSearch={onSearch}
                >
                  {purchases?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.doc_no}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Card> */}
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={materials}
          setIsModal={setIsModal}
          material={material}
          setIsEdit={setIsEdit}
          setIsDocEdit={setIsDocEdit}
          setMatIsEdit={setMatIsEdit}
          getDocsbyId={getDocsbyId}
          files={newFiles}
          type={type}
          page="vehicleStorage"
        />
      ) : (
        ''
      )}
      {/* </Space> */}
    </Form>
    // </Layout>
  );
};
export default InvoiceDetailsPage;
