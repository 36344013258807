import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  // Select,
  Input,
  // DatePicker,
  Space,
  message,
  Image,
  Spin,
  Select,
  DatePicker
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  createCosting,
  getAllCostingetails
  // getAllfgDetails,
  // getAllProdDescs,
  // getAllConsignees
} from '../../redux/slice';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
// import { PlusCircleFilled, PlusCircleOutlined } from '@ant-design/icons';

// const { TextArea } = Input;

const CreateCosting = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { allMaterials } = useSelector((state) => state.stocks);

  const [truckShow, setTruckShow] = useState(false);
  const [batches, setBatches] = useState([]);
  const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  useEffect(() => {
    handleGetAllMaterialsList();
    searchParams.get('id') ? handleGetMaterialsByID() : '';
  }, [search]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetMaterialsByID = () => {
    const payload = { id: searchParams.get('id') };
    dispatch(getAllCostingetails(payload)).then((response) => {
      if (response?.payload?.success) {
        form.setFieldsValue({
          material_id: response?.payload?.data?.[0]?.material?.code,
          material_name: response?.payload?.data?.[0]?.material?.name,
          uom: response?.payload?.data?.[0]?.material?.uom,
          batch_details_id:
            response?.payload?.data?.[0]?.batch_detail?.inhouse_batch_number,
          manufacturer_name:
            response?.payload?.data?.[0]?.material?.suppliers?.[0]
              ?.manufacturer_name,
          supplier_name:
            response?.payload?.data?.[0]?.material?.suppliers?.[0]?.name,
          invoice_num: response?.payload?.data?.[0]?.invoice_num,
          invoice_date: moment(
            response?.payload?.data?.[0]?.invoice_date,
            'YYYY-MM-DD'
          ),
          received_qty: response?.payload?.data?.[0]?.received_qty,
          total_price: response?.payload?.data?.[0]?.total_price,
          price_per_unit: response?.payload?.data?.[0]?.price_per_unit
        });
      }
    });
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onFinish = (values) => {
    setTruckShow(true);
    console.log(values);
    searchParams.get('id') ? (values['id'] = searchParams.get('id')) : '';
    dispatch(createCosting(values)).then((response) => {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.push('/material-costing-list');
      }
    });
  };

  const onChange = (id) => {
    const batches = allMaterials?.filter((material) => material.id == id);
    setBatches(batches);
    form.setFieldsValue({
      material_name: batches?.[0]?.name,
      uom: batches?.[0]?.uom
    });
    console.log(batches);
  };

  const onChangebatch = () => {
    form.setFieldsValue({
      manufacturer_name: batches?.[0]?.suppliers?.[0]?.manufacturer_name,
      supplier_name: batches?.[0]?.suppliers?.[0]?.name
    });
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title="COSTING DETAILS"
          className={styles.CustomPanel}
          extra={
            searchParams.get('id') ? (
              <EditOutlined onClick={() => setIsEdit(true)} />
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Material Code"
                name="material_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a material code"
                  optionFilterProp="children"
                  onChange={(e) => onChange(e, 'id')}
                  onSearch={onSearch}
                  disabled={searchParams.get('id')}
                >
                  {allMaterials ? (
                    allMaterials?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.code}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Material Name"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material Name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter UOM" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Inhouse Batch #"
                name="batch_details_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select a inhouse batch"
                  optionFilterProp="children"
                  onChange={(e) => onChangebatch(e)}
                  onSearch={onSearch}
                  disabled={searchParams.get('id')}
                >
                  {batches?.[0]?.batch_details?.length ? (
                    batches?.[0]?.batch_details?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.inhouse_batch_number}
                        </option>
                      );
                    })
                  ) : (
                    <Spin />
                  )}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Name"
                name="manufacturer_name"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Manufacturer Name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Supplier Name"
                name="supplier_name"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Supplier Name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="PO/Invoice num"
                name="invoice_num"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter PO/Invoice Num"
                  disabled={searchParams.get('id') && !isEdit}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="PO/Invoice Date"
                name="invoice_date"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Enter PO/Invoice date"
                  style={{ width: '100%' }}
                  disabled={searchParams.get('id') && !isEdit}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="PO/Invoice Qty"
                name="received_qty"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter PO/Invoice Qty"
                  disabled={searchParams.get('id') && !isEdit}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Total Price(In INR)"
                name="total_price"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Total Price"
                  disabled={searchParams.get('id') && !isEdit}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={12} md={24} sm={24}>
              <FormItem
                label="Price per Unit(In INR)"
                name="price_per_unit"
                rules={[
                  {
                    required: true,
                    message: 'Select Material Code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter Price per Unit"
                  disabled={searchParams.get('id') && !isEdit}
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton htmlType="button" onClick={() => history.back()}>
              Cancel
            </CustomButton>
            {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : (
              <CustomButton type="primary" htmlType="submit" loading={loading}>
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default CreateCosting;
