import React, { useEffect, useCallback } from 'react';
import {
  Form,
  Card,
  Col,
  Row,
  Space,
  Input,
  message,
  Select,
  DatePicker,
  Alert,
  Popconfirm,
  Badge,
  Checkbox,
  InputNumber,
  Button
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
// import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getallprodMaterials,
  updateProduct,
  updateLots,
  modifiedDate
} from '../../redux/slice';
// import Layout from '@/common/Layout';
import { useState } from 'react';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  getAllProductNames,
  getAllProductDetails
} from '@/features/prodUser/redux/slice';
import CustomModal from '@/common/CustomModal';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import VerificationModal from '@/features/material/components/VerificationModal';
// import CustomModal from '@/common/CustomModal';

const { Meta } = Card;
// const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';

const ProductDetailsPage = ({
  getAllMaterilLoading,
  data,
  setData,
  extraGranulardata,
  setExtraGranulardata
}) => {
  const { productNames, productDetails } = useSelector(
    (state) => state.product
  );
  const { allMaterials } = useSelector((state) => state.stocks);

  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Quality Assurance');
  const { Superadminproddata } = getRoles('Production');

  // const [data, setData] = useState({});
  // const [extraGranulardata, setExtraGranulardata] = useState([]);
  // const [materialData, setMaterialData] = useState([]);
  // const [materialDataAPI, setMaterialDataAPI] = useState([]);
  const [status, setStatus] = useState('');
  const [edit, setEdit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [materialId, setMaterialId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [stages, setStages] = useState([]);
  const [lots, setLots] = useState(0);
  const [parts, setParts] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [search, setSearch] = useState('');
  const [addMat, setAddMat] = useState(false);
  const [materialdetails, setMaterialdetails] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [dispenses, setDispenses] = useState([]);
  const [sequence, setSequence] = useState('');
  const [execess, setExecess] = useState('');
  const [stage, setStage] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [bmrData, setBmrData] = useState();
  // const [newmaterials, setNewMaterials] = useState({
  //   product_id: '',
  //   material_id: '',
  //   supplier_id: '',
  //   quantity_for_batch: '',
  //   stage_group: '',
  //   standard_yield_qty: '',
  //   dispensing_required: '',
  //   is_extra: '',
  //   compensation_material: '',
  //   qty_dispensed: '',
  //   part_qty: '',
  //   lot_qty: '',
  //   supplier_name: '',
  //   material_name:
  //     '',
  //   uom: '',
  //   dispensing_sequence: '',
  //   is_assay: '',
  //   is_excess: '',
  //   is_api: ''
  // });
  // const [productType, setProductType] = useState('');

  console.log(
    extraGranulardata,
    setStatus,
    setIsUpdate,
    isUpdate,
    status,
    materialId,
    lots,
    parts,
    search
  );
  useEffect(() => {
    handleGetAllProductMaterials();
    // populatedFields();
  }, []);

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search]);

  const handleGetAllMaterialsList = () => {
    const payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  useEffect(() => {
    handleGetProductNames();
  }, []);

  const handleGetProductNames = () => {
    dispatch(getAllProductNames());
  };

  const handleGetProductDetails = (e) => {
    // setProductType(e);
    let payload = { product_type_code: e };
    dispatch(getAllProductDetails(payload));
  };

  const handleProductDetails = (e) => {
    let shelf_life = productDetails.filter(
      (data) => data.product_details === e
    );
    console.log(shelf_life);
    form.setFieldsValue({ shelf_life: shelf_life[0]?.shelf_life });
  };

  const handleDeleteUser = () => {
    let payload = {
      id: params?.id,
      type: 'update',
      is_active: false
    };
    dispatch(updateProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push('/product-list');
      }
    });
  };
  const handleGetAllProductMaterials = () => {
    let payload = {
      type:
        (user?.role == 'qc_initiator' && user?.level == 3) ||
        Superadmindata?.[0]?.level == 3
          ? 'product_view'
          : user?.role == 'prod_user'
          ? 'product_view'
          : '',
      id: params?.id
    };
    dispatch(getallprodMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setData(response?.payload?.data);
        const extra_granular_data =
          response?.payload?.data?.bmr_product_materials.filter(
            (item) => item?.is_extra == true
          );
        setExtraGranulardata(extra_granular_data);
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({ product_name: data?.name });
    form.setFieldsValue({ is_product: data?.is_product });
    form.setFieldsValue({ ref_mfc: data?.ref_mfc });
    form.setFieldsValue({ shelf_life: data?.shelf_life });
    form.setFieldsValue({ manufacturing_site: data?.manufacturing_site });
    form.setFieldsValue({ customer_details: data?.customer_details });
    form.setFieldsValue({ bmr_bpr_number: data?.bmr_bpr_code });
    form.setFieldsValue({
      effective_date: moment(data?.effective_date, dateFormat)
    });
    form.setFieldsValue({ theoretical_yield: data?.theoretical_yield });
    form.setFieldsValue({
      theoretical_batch_size: data?.theoretical_batch_size
    });
  }, [edit]);

  // const handleEdit = () => {
  //   setShowModal(true);
  // };
  const onFinish = () => {};
  const verifybmr = () => {
    console.log(data?.is_cloned);
    setIsModal(true);
    const payload =
      // isUpdate
      //   ? {
      {
        id: params.id,
        product_name: form.getFieldValue('product_name'),
        bmr_bpr_number: form.getFieldValue('bmr_bpr_number'),
        effective_date: form.getFieldValue('effective_date'),
        theoretical_yield: form.getFieldValue('theoretical_yield'),
        theoretical_batch_size: form.getFieldValue('theoretical_batch_size'),
        ref_mfc: form.getFieldValue('ref_mfc'),
        shelf_life: form.getFieldValue('shelf_life'),
        manufacturing_site: form.getFieldValue('manufacturing_site'),
        customer_details: form.getFieldValue('customer_details'),
        is_product: form.getFieldValue('is_product'),
        is_cloned: data?.is_cloned,
        type: 'update'
      };
    setBmrData(payload);
  };
  console.log(bmrData);
  const handleSubmit = (values) => {
    console.log(form.getFieldValue('bmr_bpr_number'));
    const payload =
      // isUpdate
      //   ? {
      {
        id: params.id,
        product_name: values.product_name,
        bmr_bpr_number: form.getFieldValue('bmr_bpr_number'),
        effective_date: form.getFieldValue('effective_date'),
        theoretical_yield: values.theoretical_yield,
        theoretical_batch_size: values.theoretical_batch_size,
        ref_mfc: values.ref_mfc,
        shelf_life: values.shelf_life,
        manufacturing_site: values.manufacturing_site,
        customer_details: values.customer_details,
        is_product: values.is_product,
        type: 'update'
        //   }
        // : {
        //     status: status,
        //     id: params.id,
        //     comments: values.comments
      };
    dispatch(updateProduct(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        setEdit(false);
        handleGetAllProductMaterials();
        handleGetProductNames();
        history.push(`/bmr-params-view/${params.id}`);
      }
    });
  };

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleMaterial = (id) => {
    form.resetFields();
    setMaterials([]);
    setMaterialId(id);
    setShowModal(true);
    setAddMat(false);
  };

  const handlelotsChange = (e, index, i) => {
    console.log(e.target.value, index, i);
    // console.log(total_qty);
    const list = [...stages];
    setLots(e.target.value);
    let lots = [];
    for (var j = 0; j < e.target.value; j++) {
      lots.push({ lot: '', parts: [] });
    }
    console.log(lots);
    // list[index].materials[i].lot_qty = e.target.value;
    // list[index].materials[i].lots = lots;
    console.log(stages);
    setMaterials(lots);
    setStages(list);
  };

  const handlepartsChange = (e) => {
    // console.log(e, index, i);
    setParts(e.target.value);
    const list = [...materials];
    let parts = [];
    for (var j = 0; j < e.target.value; j++) {
      parts.push({ part: '' });
    }

    list.map((item) => {
      item.parts = parts;
    });
    // list[index].materials[i].part_qty = e.target.value;
    setMaterials(list);
  };

  const calculateQty = () => {
    const material = data?.bmr_product_materials.filter(
      (data) => data.id == materialId
    );
    console.log(quantity.quantity_for_batch);
    let batch_qty = addMat
      ? quantity.quantity_for_batch
      : material[0].quantity_for_batch;
    console.log(execess.excess_qty);
    let excess_qty = addMat
      ? execess.excess_qty || 0
      : material[0].excess_qty || 0;
    let cal = (parseFloat(batch_qty) * parseFloat(excess_qty)).toFixed(3) / 100;
    let total_qty = parseFloat(batch_qty) + parseFloat(cal);
    let lot_qty = total_qty / lots;
    let part_qty = lot_qty / parts;
    const list = [...materials];
    materials.qty_dispensed = total_qty;
    materials.map((item) => {
      item['lot'] = parseFloat(lot_qty).toFixed(3);
      item.parts.map((part) => {
        part['part'] = parseFloat(part_qty).toFixed(3);
      });
    });

    setMaterials(list);
    // list[index].lots.map((item, index, i) => {
    //   // var name = `lot${index}${i}`;
    //   form.setFieldsValue( {`lot${index}${i}`: 30} );
    // });

    console.log(stages, 'stages');
  };

  const handleSubmitLots = () => {
    const payload = addMat
      ? {
          product_id: params.id,
          // bmr_id: materialId,
          material_id: materialdetails.material_id,
          supplier_id: materialdetails.supplier_id,
          quantity_for_batch: quantity.quantity_for_batch,
          stage_group: stage?.stage,
          standard_yield_qty: '',
          dispensing_required: dispenses?.dispensing_required,
          is_extra: dispenses?.is_extra,
          compensation_material: dispenses?.compensation_material,
          // qty_dispensed: dispenses[0]?.compensation_material,
          part_qty: parts,
          lot_qty: lots,
          supplier_name: materialdetails?.supplier_name,
          material_name: materialdetails?.material_name,
          uom: materialdetails?.uom,
          // dispensing_sequence: sequence,
          is_assay: dispenses?.is_assay,
          is_excess: dispenses?.is_excess,
          is_api: dispenses?.is_api,
          lots: materials
        }
      : {
          product_id: params.id,
          bmr_id: materialId,
          lots: materials
        };
    dispatch(updateLots(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        setShowModal(false);
        handleGetAllProductMaterials();
        handleGetProductNames();
        history.push(`/bmr-params-view/${params.id}`);
      }
    });
  };
  const onChange = (value) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );

    const list = [...materialdetails];
    list.material_id = material[0]?.id;
    list.supplier_id = material[0]?.suppliers[0]?.id;
    list.supplier_name = material[0]?.suppliers[0]?.manufacturer_name;
    list.material_name = material[0]?.name;
    list.uom = material[0]?.uom;
    list.compensation_material =
      material[0]?.compensation_material == 'Yes' ? true : false;
    setMaterialdetails(list);
    // form.setFieldsValue({ material_name: material[0]?.name });
    // setCode(material[0]);
  };

  console.log(materials);
  const onSearch = (value) => {
    setSearch(value);
  };

  const handleQuantityChange = (e) => {
    const list = [...quantity];
    list.quantity_for_batch = e.target.value;
    setQuantity(list);
  };

  const onChangeCheck = (e) => {
    console.log(e);
    const list = [...dispenses];

    list.dispensing_required = e.includes('Dispensing');
    list.compensation_material = e.includes('Compensatory');
    list.is_api = e.includes('API');
    list.is_assay = e.includes('Assay');
    list.is_extra = e.includes('is_extra');
    list.is_excess = e.includes('Excess');

    setDispenses(list);
  };

  const handleExcessChange = (e) => {
    console.log(e);
    const list = [...execess];
    list.excess_qty = e || 0;
    setExecess(list);
  };

  const handleDispensingSeq = (e) => {
    const list = [...sequence];
    list.dispensing_sequence = e;
    setSequence(list);
  };

  const handleStageChange = (e) => {
    const list = [...stage];
    list.stage = e;
    setStage(list);
  };
  const list = [];

  for (let i = 1; i <= 30; i++) {
    list.push(i);
  }

  const handleAdd = () => {
    setShowModal(true);
    setAddMat(true);
  };
  // const handleClose = () => {
  //   setShowModal(false);
  // };

  const [isEditing, setIsEditing] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(
    data?.effective_date ? moment(data?.effective_date) : null
  );

  useEffect(() => {
    form.setFieldsValue({
      effective_date: effectiveDate
    });
  }, [effectiveDate, form]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDateChange = (date) => {
    setEffectiveDate(date);
  };

  const handleSaveClick = () => {
    console.log(
      'Updated Effective Date:',
      effectiveDate ? effectiveDate.format('YYYY-MM-DD') : null
    );
    data.effective_date = effectiveDate
      ? effectiveDate.format('YYYY-MM-DD')
      : null;

    const payload = {
      effective_date: data.effective_date,
      id: params?.id,
      type: 'delete'
    };

    dispatch(modifiedDate(payload)).then(function (response) {
      console.log('>>>>', response);
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditing(false);
      } else {
        message.error('Failed to update effective date');
      }
    });
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {data?.comments ? (
          <Alert
            // message="Comments"
            description={data?.comments}
            type="info"
            className={styles.alert}
            showIcon
          />
        ) : (
          ''
        )}
        <br />
        <Card
          title={
            <span
              style={{
                fontWeight: 'bold',
                color: '#c91761',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {edit
                ? `Edit Dispensing Request - BMR Based`
                : user?.role === 'qc_initiator'
                ? `Approval for New Product`
                : `Product Details`}
              {user?.role === 'qc_initiator' && (
                <EditOutlined
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={handleEditClick}
                />
              )}
            </span>
          }
          extra={
            <Space>
              {(user?.role == 'prod_user' &&
                user?.level == 3 &&
                data?.status !== 'Approved') ||
              (user?.role == 'prod_user' &&
                user?.level == 2 &&
                data?.status == 'Rejected') ||
              Superadminproddata?.[0]?.level == 3 ? (
                <Space>
                  <CustomButton onClick={() => setEdit(true)}>
                    <EditOutlined />
                  </CustomButton>
                  <Popconfirm
                    title={`Are you sure do you want to delete this product?`}
                    visible={visibility(data)}
                    key={data.id}
                    onConfirm={(e) => {
                      e.stopPropagation();
                      handleDeleteUser({
                        id: data.id,
                        is_active: !data?.is_active
                      });
                    }}
                    onCancel={(e) => {
                      e.stopPropagation();
                      setShowDisablePopup({ [data.id]: false });
                    }}
                    okText="Delete"
                    cancelText="No"
                    okButtonProps={{
                      loading: getAllMaterilLoading,
                      type: data.is_active ? 'danger' : 'primary'
                    }}
                  >
                    <CustomButton>
                      <DeleteOutlined />
                    </CustomButton>
                  </Popconfirm>
                </Space>
              ) : (
                ''
              )}
              {data?.is_cloned && user?.level !== 3 ? (
                <EditOutlined onClick={() => setEdit(true)} />
              ) : (
                ''
              )}
            </Space>
          }
          className={styles.cardStyles_approver1}
        >
          {edit ? (
            <>
              <Row gutter={24}>
                {/* <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Product Name"
                    name="product_name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter product name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter product name" />
                  </FormItem>
                </Col> */}
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Product Name"
                    name="is_product"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Theoretical Yield/Batch Size'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Product Type"
                      onChange={(e) => handleGetProductDetails(e)}
                    >
                      {productNames.map((item, index) => {
                        return (
                          <option value={item?.product_name_type} key={index}>
                            {item?.product_name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>

                <Col xs={24} xl={12} span={24} md={24} sm={24}>
                  <FormItem
                    label="Product Details"
                    name="product_name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter detail name of the Product'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Product Details"
                      onChange={(e) => handleProductDetails(e)}
                    >
                      {productDetails?.map((item, index) => {
                        return (
                          <option value={item?.product_details} key={index}>
                            {item?.product_details}
                          </option>
                        );
                      })}
                    </Select>
                    {/* <Input placeholder="Enter detail name of the Product" /> */}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Ref. MFC#"
                    className={styles.customLable}
                    name="ref_mfc"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Ref MFC'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Ref. MFC#" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Shelf life"
                    name="shelf_life"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Shelf life'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Shelf life" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Manufacturing Site"
                    name="manufacturing_site"
                    rules={[
                      {
                        required: true,
                        message: 'Select Manufacturing Site'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select  Manufacturing Site"
                      optionFilterProp="children"
                    >
                      <option value="1">
                        Graviti Pharmaceuticals Pvt. Ltd.
                      </option>
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <FormItem
                    label="Market / Customer Details"
                    name="customer_details"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Market / Customer Details'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Market / Customer Details" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="BMR / BPR Number"
                    name="bmr_bpr_number"
                    rules={[
                      {
                        required: false,
                        message: 'Enter BMR/BPR Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter BMR/BPR Number" />
                  </FormItem>
                </Col>
                {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Product Batch Number"
                name="product_batch_number"
                rules={[
                  {
                    required: false,
                    message: 'Enter Product Batch Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Product Batch Number" />
              </FormItem>
            </Col> */}
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Effective Date"
                    name="effective_date"
                    rules={[
                      {
                        required: false,
                        message: 'Select Effective Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Effective Date"
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Theoretical Yield of Sifted & Milled Granules"
                    name="theoretical_yield"
                    rules={[
                      {
                        required: false,
                        message:
                          'Enter Theoretical Yield of Sifted & Milled Granules'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Theoretical Yield of Sifted & Milled Granules" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Theoretical Yield/Batch Size"
                    name="theoretical_batch_size"
                    rules={[
                      {
                        required: false,
                        message: 'Enter Theoretical Yield/Batch Size'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Theoretical Yield/Batch Size" />
                  </FormItem>
                </Col>
              </Row>
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    className={styles.inwardButton}
                    // onClick={handleClose}
                    type="primary"
                    // size="small"
                  >
                    Close
                  </CustomButton>
                  {user?.level == 2 ? (
                    <CustomButton
                      className={styles.inwardButton}
                      // htmlType="submit"
                      type="primary"
                      // size="small"
                      onClick={handleSubmit}
                    >
                      Update
                    </CustomButton>
                  ) : (
                    <CustomButton
                      className={styles.inwardButton}
                      // htmlType="submit"
                      type="primary"
                      // size="small"
                      onClick={verifybmr}
                      // onClick={() => setIsModal(true)}
                    >
                      Initiate Verification
                    </CustomButton>
                  )}
                </Space>
              </FormItem>
            </>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={24} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Name :</span>
                  {'  '}
                  <span>{data?.name}</span>
                </p>
              </Col>
              {/* <Col xs={24} xl={12} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Batch :</span>
                  {'  '}
                  <span>
                    {data?.dispensing_requests?.[0]?.new_product_batch_number}
                  </span>
                </p>
              </Col> */}
              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>BMR/BPR Number :</span>
                  {'  '}
                  <span>{data?.bmr_bpr_code}</span>
                </p>
              </Col>
              <Col xs={24} xl={8} span={12}>
                {isEditing ? (
                  <>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Effective Date :
                      </span>
                      {'  '}
                      <DatePicker
                        value={effectiveDate}
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                        style={{ marginLeft: '8px' }}
                      />
                    </p>
                    <Button
                      type="primary"
                      style={{ marginLeft: '8px' }}
                      onClick={handleSaveClick}
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Effective Date :</span>
                    {'  '}
                    <span>
                      {data?.effective_date
                        ? moment(data?.effective_date).format('DD/MM/YYYY')
                        : 'NA'}
                    </span>
                  </p>
                )}
              </Col>
              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Batch Size :</span>
                  {'  '}
                  <span>{data?.theoretical_batch_size || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Product Details :</span>
                  {'  '}
                  <span>{data?.product_details || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Ref. MFC# :</span>
                  {'  '}
                  <span>{data?.ref_mfc || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Shelf life :</span>
                  {'  '}
                  <span>{data?.shelf_life || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Manufacturing Site :
                  </span>
                  {'  '}
                  <span>{data?.manufacturing_site || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Market/Customer Details :
                  </span>
                  {'  '}
                  <span>{data?.customer_details || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Theoretical Yield of Sifted & Milled Granules :
                  </span>
                  {'  '}
                  <span>{data?.theoretical_yield || 'NA'}</span>
                </p>
              </Col>

              <Col xs={24} xl={8} span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Theoretical Yield/Batch Size :
                  </span>
                  {'  '}
                  <span>{data?.theoretical_batch_size || 'NA'}</span>
                </p>
              </Col>
            </Row>
          )}
        </Card>
        <br />
        <Card
          className={styles.subpanel}
          title={
            <span style={{ color: '#c91761', fontWeight: 'bold' }}>
              Dispensing Details
            </span>
          }
          extra={
            data?.is_cloned ? (
              <CustomButton
                size={'small'}
                type="primary"
                style={{ float: 'right' }}
                onClick={() => handleAdd()}
              >
                Add Material
              </CustomButton>
            ) : (
              ''
            )
          }
        >
          <Meta
            title={
              ''
              // <Space>
              //   <span style={{ color: '#c91761', fontWeight: 'bold' }}>
              //     Dispensing Details
              //   </span>
              //   <CustomButton
              //     size={'small'}
              //     type="primary"
              //     style={{ float: 'right' }}
              //   >
              //     Add Material
              //   </CustomButton>
              // </Space>
            }
            description={
              <span style={{ fontWeight: 'bold' }}>
                Note:- When two or more materials code options are available for
                a material, that entire material quantity shall be sourced from
                any one of the material
              </span>
            }
            // className={`${styles.cardStyles_approver}`}
          />

          <br />
          <Row
            gutter={24}
            // direction="vertical"
            // size="middle"
            className={`${styles.card_container}`}
          >
            {data?.bmr_product_materials?.map((item, index) => {
              return (
                <>
                  <Col xs={24} xl={12} span={12} key={index}>
                    <Badge.Ribbon text={item.stage_group_name} color="pink">
                      <Card
                        title={
                          <b style={{ color: '#e80e94' }}>
                            {item?.material?.code}
                          </b>
                        }
                        size="small"
                        className={styles.cardStyles_approver1}
                        extra={
                          // data?.is_cloned ||
                          (user?.role == 'prod_user' &&
                            user?.level == 2 &&
                            data?.status == 'Rejected') ||
                          (user?.role == 'prod_user' &&
                            user?.level == 3 &&
                            data?.status !== 'Approved') ? (
                            <EditOutlined
                              style={{ marginRight: '90px' }}
                              onClick={() => handleMaterial(item?.id)}
                            />
                          ) : (
                            ''
                          )
                        }
                      >
                        <Row gutter={24}>
                          <Col xs={24} xl={12} span={12}>
                            <p>
                              <span style={{ fontWeight: 'bold' }}>
                                Name :{' '}
                              </span>
                              {'  '}
                              <span> {item?.material?.name}</span>
                            </p>
                          </Col>
                          <Col xs={24} xl={24} span={12}>
                            <p>
                              <span style={{ fontWeight: 'bold' }}>
                                Source :
                              </span>
                              {'  '}
                              <span>
                                {item.material?.suppliers?.manufacturer_name}
                              </span>
                            </p>
                          </Col>

                          <Col xs={24} xl={24} span={12}>
                            <p>
                              <span style={{ fontWeight: 'bold' }}>
                                Standard Qty for Batch :{' '}
                              </span>
                              {'  '}
                              <span> {item.quantity_for_batch}</span>
                            </p>
                          </Col>
                          {item?.is_extra ? (
                            <Col xs={24} xl={24} span={12}>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>
                                  Quantity to Dispense :{' '}
                                </span>
                                {'  '}
                                <span> {item.qty_dispensed}</span>
                              </p>
                            </Col>
                          ) : (
                            item?.material_lots?.map((lot_val, index) => {
                              const parts = lot_val?.material_parts.sort(
                                function (a, b) {
                                  return a.id - b.id;
                                }
                              );

                              return (
                                <>
                                  <Col
                                    xs={24}
                                    xl={12}
                                    span={12}
                                    key={index}
                                    style={{ fontSize: '13px' }}
                                  >
                                    <p>
                                      <span>Lot {index + 1} : </span>
                                      {parseFloat(lot_val?.lot_value).toFixed(
                                        3
                                      )}
                                      <br />
                                      {/* </p> */}
                                      {parts?.map((part, index) => {
                                        return (
                                          <Space size={10} key={index}>
                                            <span>Part {index + 1} : </span>
                                            {parseFloat(
                                              part?.part_value
                                            ).toFixed(3)}
                                            {'  '}
                                          </Space>
                                        );
                                      })}
                                    </p>
                                  </Col>
                                </>
                              );
                            })
                          )}
                          <Col xs={24} xl={24} span={12}>
                            <p>
                              <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                Dispensing Sequense:{' '}
                              </span>
                              {item?.dispensing_sequence}
                            </p>
                          </Col>
                        </Row>
                      </Card>
                      <br />
                    </Badge.Ribbon>
                  </Col>
                </>
              );
            })}
          </Row>
        </Card>
        <br />

        {showModal ? (
          <CustomModal
            title="Edit Dispensing Details"
            visible={showModal}
            width="100%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <Form form={form} layout="vertical">
              {addMat ? (
                <Row gutter={24}>
                  <Col xs={24} xl={12} span={12} md={24} sm={24}>
                    <FormItem
                      label="Stages"
                      rules={[
                        {
                          required: true,
                          message: 'Material Code'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Select a Stage"
                        optionFilterProp="children"
                        onChange={(e) => handleStageChange(e)}
                        onSearch={onSearch}
                      >
                        {data?.stage_groups?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.stage_name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row gutter={24}>
                {addMat ? (
                  <>
                    <Col xs={24} xl={3} span={12} md={24} sm={24}>
                      <FormItem
                        label="Material"
                        rules={[
                          {
                            required: true,
                            message: 'Material Code'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          showSearch
                          placeholder="Select a material code"
                          optionFilterProp="children"
                          onChange={(e) => onChange(e)}
                          onSearch={onSearch}
                        >
                          {allMaterials?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.code}
                              </option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={12} md={24} sm={24}>
                      <FormItem
                        label="Std Qty"
                        rules={[
                          {
                            required: false,
                            message: 'Quantity for Batch'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter Quantity for Batch"
                          onChange={(e) => handleQuantityChange(e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        rules={[
                          {
                            required: false,
                            message: 'Enter Quantity for Batch'
                          }
                        ]}
                        className={styles.customCheckBox}
                      >
                        <Checkbox.Group
                          style={{
                            width: '100%'
                          }}
                          onChange={(e) => onChangeCheck(e)}
                        >
                          <Row>
                            <Col span={5}>
                              <Checkbox value="Dispensing">Dispensing</Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="is_extra">Is Extra</Checkbox>
                            </Col>
                            <Col span={6}>
                              <Checkbox value="Compensatory">
                                Compensatory
                              </Checkbox>
                            </Col>

                            <Col span={3}>
                              <Checkbox value="API">API</Checkbox>
                            </Col>
                            <Col span={3}>
                              <Checkbox value="Assay">Assay</Checkbox>
                            </Col>

                            <Col span={2}>
                              <Checkbox value="Excess">Excess</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={3} span={12} md={24} sm={24}>
                      <FormItem
                        label={`Excess Qty (%) `}
                        rules={[
                          {
                            required: false,
                            message: 'Enter Excess Quantity'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <InputNumber
                          min={0}
                          style={{
                            width: '100%'
                          }}
                          placeholder="Enter Excess Quantity"
                          onChange={(e) => handleExcessChange(e)}
                          // disabled={!material?.is_excess}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={2} span={12} md={24} sm={24}>
                      <FormItem
                        label="Sequence"
                        rules={[
                          {
                            required: false,
                            message: 'Select Dispensing Sequence'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Select
                          showSearch
                          placeholder="Sequence"
                          optionFilterProp="children"
                          onChange={(e) => handleDispensingSeq(e)}
                        >
                          {list?.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </>
                ) : (
                  ''
                )}
                <Col xs={24} xl={24} span={12} md={24} sm={24}>
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="How many lots?"
                        rules={[
                          {
                            required: false,
                            message: 'Enter number of lots'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter number of lots"
                          onChange={(e) => handlelotsChange(e)}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} xl={12} span={12} md={24} sm={24}>
                      <FormItem
                        label="How many parts for each lot?"
                        rules={[
                          {
                            required: false,
                            message: 'Enter number of parts'
                          }
                        ]}
                        className={styles.customLable}
                      >
                        <Input
                          placeholder="Enter number of parts"
                          onChange={(e) => handlepartsChange(e)}
                        />
                      </FormItem>
                    </Col>
                    {materials.map((sub_item, j) => {
                      return (
                        <Col xs={24} xl={12} span={12} md={24} sm={24} key={j}>
                          <FormItem
                            label={`Lot -${j + 1}`}
                            rules={[
                              {
                                required: false,
                                message: 'Enter Lot Quantity'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Enter Lot Quantity"
                              // onChange={(e) => handleLotChange(e, j)}
                              value={sub_item.lot}
                            />
                          </FormItem>
                          <Row gutter={24}>
                            {sub_item?.parts?.map((part, k) => {
                              return (
                                <Col
                                  xs={12}
                                  xl={12}
                                  span={12}
                                  md={24}
                                  sm={24}
                                  key={j}
                                >
                                  <FormItem
                                    label={`Part -${k + 1}`}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Enter part Quantity'
                                      }
                                    ]}
                                    className={styles.customLable}
                                  >
                                    <Input
                                      placeholder="Enter part Quantity"
                                      // onChange={(e) =>
                                      //   handlePartChange(e, index, i, j, k)
                                      // }
                                      value={part.part}
                                    />
                                  </FormItem>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                  <FormItem className="text-center">
                    <Space size={5}>
                      <CustomButton
                        className={styles.inwardButton}
                        type="primary"
                        ghost
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        className={styles.inwardButton}
                        type="primary"
                        ghost
                        onClick={() => calculateQty()}
                      >
                        Calculate Lot/Part Quantities
                      </CustomButton>
                      <CustomButton
                        className={styles.inwardButton}
                        type="primary"
                        // ghost
                        onClick={() => handleSubmitLots()}
                      >
                        Submit
                      </CustomButton>
                    </Space>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </CustomModal>
        ) : (
          ''
        )}
        {isModal ? (
          <VerificationModal
            isModal={isModal}
            materials={bmrData}
            setIsModal={setIsModal}
            // material={material}
            // setIsEdit={setIsEdit}
            page="bmrupdate"
            setEdit={setEdit}
          />
        ) : (
          ''
        )}
      </Form>
      {/* </Layout> */}
    </>
  );
};
export default ProductDetailsPage;
