import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import { Card, Col, DatePicker, Form, Input, Row, Space, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { createPrep, getPrep } from '../../redux/slice';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const CreateIPAPreperationLog = () => {
  const [form] = Form.useForm();
  const [isSaved, setIsSaved] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    moment().format('YYYY-MM-DD HH:mm')
  );
  const [products, setProducts] = useState([
    {
      prep_lts: '',
      consum_qty: '',
      consum_ltrs: '',
      consum_bal_qty: '',
      consum_total_qty: ''
    }
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { returnableData } = useSelector((state) => state.return);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const { TextArea } = Input;
  // const [dropdownGroups, setDropdownGroups] = useState([{ id: 1 }]);
  const dispatch = useDispatch();
  // const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleSave = () => {
    setIsSaved(true);
    message.success('Form saved successfully!');
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleRemove = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, key, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][key] = value;

    const prep_lts = parseFloat(updatedProducts[index].prep_lts) || 0;
    const consum_qty = parseFloat(updatedProducts[index].consum_qty) || 0;
    const consum_ltrs = parseFloat(updatedProducts[index].consum_ltrs) || 0;

    updatedProducts[index].consum_bal_qty = (prep_lts - consum_qty).toFixed(2);
    updatedProducts[index].consum_total_qty = (
      consum_qty + consum_ltrs
    ).toFixed(2);

    setProducts(updatedProducts);
  };

  const handleAdd = () => {
    const lastProduct = products[products.length - 1];
    setProducts((prevProducts) => [
      ...prevProducts,
      {
        prep_lts: lastProduct.consum_bal_qty || '',
        consum_qty: '',
        consum_ltrs: '',
        consum_bal_qty: lastProduct.consum_bal_qty || '',
        consum_total_qty: ''
      }
    ]);
  };

  useEffect(() => {
    if (id) {
      handleGetAllReturnGatepass();
    }
  }, [id]);

  useEffect(() => {
    if (id && returnableData) {
      const recordData = returnableData[0];

      if (recordData) {
        form.setFieldsValue({
          prep_date: moment(recordData.prep_date, 'YYYY-MM-DD'),
          prep_qty: recordData.prep_qty,
          prep_lts: recordData.prep_lts,
          emp_id: recordData.emp_id,
          password: recordData.password
        });

        if (Array.isArray(recordData.consumption_details)) {
          const cleaningWrappingSecs = recordData.consumption_details.map(
            (record, index) => ({
              id: index + 1,
              consum_date: moment(record.consum_date, 'YYYY-MM-DD'),
              consum_qty: record.consum_qty,
              consum_bal_qty: record.consum_bal_qty,
              consum_ltrs: record.consum_ltrs,
              consum_total_qty: record.consum_total_qty,
              consum_prep_by: record.consum_prep_by,
              consum_verf_by: record.consum_verf_by,
              remarks: record.remarks
            })
          );

          setProducts(cleaningWrappingSecs);

          cleaningWrappingSecs.forEach((field, index) => {
            form.setFieldsValue({
              [`id_${index + 1}`]: field.id,
              [`consum_date_${index + 1}`]: field.consum_date,
              [`consum_qty_${index + 1}`]: field.consum_qty,
              [`consum_bal_qty_${index + 1}`]: field.consum_bal_qty,
              [`consum_ltrs_${index + 1}`]: field.consum_ltrs,
              [`consum_total_qty_${index + 1}`]: field.consum_total_qty,
              [`consum_prep_by_${index + 1}`]: field.consum_prep_by,
              [`consum_verf_by_${index + 1}`]: field.consum_verf_by,
              [`remarks_${index + 1}`]: field.remarks
            });
          });
        }
      }
    }
  }, [id, returnableData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('YYYY-MM-DD HH:mm'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleGetAllReturnGatepass = () => {
    let payload = {
      id: id || ''
    };
    dispatch(getPrep(payload));
  };

  const onFinish = (values) => {
    const consumption_details = products.map((product, index) => ({
      consum_date: moment().format('YYYY-MM-DD'),
      consum_qty: product.consum_qty || values[`consum_qty_${index}`],
      consum_bal_qty: product.consum_bal_qty,
      consum_ltrs: product.consum_ltrs || values[`consum_ltrs_${index}`],
      consum_total_qty: product.consum_total_qty,
      consum_prep_by: values[`consum_prep_by_${index}`] || '',
      consum_verf_by: values[`consum_verf_by_${index}`] || '',
      remarks: values[`remarks_${index}`] || ''
    }));

    // Ensure all required fields are filled
    const hasNullValues = consumption_details.some(
      (detail) =>
        !detail.consum_qty || !detail.consum_prep_by || !detail.consum_verf_by
    );
    if (hasNullValues) {
      message.error('Please fill out all required fields.');
      return;
    }

    const payload = {
      prep_date: values.prep_date,
      prep_qty: values.prep_qty,
      prep_lts: values.prep_lts,
      emp_id: values.emp_id,
      password: values.password,
      consumption_details
    };

    dispatch(createPrep(payload)).then((response) => {
      if (response?.payload?.success) {
        const successMessage = 'IPA Preparation Log submitted successfully';
        message.success(successMessage);
        setIsModalVisible(false);
        history.push('ipa-preperation-log');
      } else {
        message.error(response.payload?.error || 'Submission failed');
      }
    });
  };

  // const isSaveDisabled = products.some(
  //   (product) =>
  //     !product.consum_qty ||
  //     !product.consum_ltrs ||
  //     !product.consum_prep_by ||
  //     !product.consum_verf_by
  // );

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className={styles.panelborder}
      >
        <Card title="70% IPA Preparation Log" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={24} span={24} md={24} sm={24}>
              <Card>
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                  <Col span={8}>
                    <FormItem label="Date of Dispensing" name="prep_date">
                      <DatePicker
                        placeholder="Select date"
                        style={{ width: '100%' }}
                        disabled={isSaved}
                        value={
                          form.getFieldValue('prep_date')
                            ? moment(form.getFieldValue('prep_date'))
                            : null
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Dispensed Quantity (kg)" name="prep_qty">
                      <Input
                        placeholder="Enter Dispensed Quantity"
                        disabled={isSaved}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Quantity (in Liters) (A)" name="prep_lts">
                      <Input
                        placeholder="Enter Quantity"
                        disabled={isSaved}
                        onChange={(e) =>
                          handleInputChange(0, 'prep_lts', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '16px'
                  }}
                >
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="submit"
                    type="primary"
                    style={{ borderRadius: '5px' }}
                    disabled={isSaved}
                    onClick={handleSave}
                  >
                    Save
                  </CustomButton>
                </Col>
              </Card>
            </Col>
          </Row>
          <br />
          {products.map((product, index) => (
            <Card
              key={index}
              title={`100% IPA Consumption Details`}
              extra={
                index === 0 ? (
                  <PlusCircleOutlined onClick={handleAdd} />
                ) : (
                  <DeleteOutlined onClick={() => handleRemove(index)} />
                )
              }
            >
              <Row gutter={24}>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem label="Date & Time">
                    <Input value={currentTime} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem label="Quantity Used (B)">
                    <Input
                      placeholder="Enter Quantity Used"
                      value={product.consum_qty}
                      onChange={(e) =>
                        handleInputChange(index, 'consum_qty', e.target.value)
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem label="Balance Qty in Liters (A-B)">
                    <Input disabled value={product.consum_bal_qty} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <h1
                    style={{
                      textAlign: 'start',
                      marginBottom: '25px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#c91761'
                    }}
                  >
                    70% IPA Preparation Details
                  </h1>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem label="Consumed in Ltrs (C)">
                    <Input
                      value={product.consum_ltrs}
                      placeholder="Enter Ltrs Consumed"
                      onChange={(e) =>
                        handleInputChange(index, 'consum_ltrs', e.target.value)
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem label="Total Quantity (B+C)">
                    <Input value={product.consum_total_qty} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Prepared By (User id & Date)"
                    name={`consum_prep_by_${index}`}
                  >
                    <Input
                      value={product.consum_prep_by}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          'consum_prep_by',
                          e.target.value
                        )
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={12} md={24} sm={24}>
                  <FormItem
                    label="Verified By (User id & Date)"
                    name={`consum_verf_by_${index}`}
                  >
                    <Input
                      value={product.consum_verf_by}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          'consum_verf_by',
                          e.target.value
                        )
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} xl={16} span={12} md={24} sm={24}>
                  <FormItem label="Remarks" name={`remarks_${index}`}>
                    <Input
                      value={product.remarks}
                      onChange={(e) =>
                        handleInputChange(index, 'remarks', e.target.value)
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
            </Card>
          ))}

          <FormItem className="text-center">
            <Space style={{ marginTop: '15px' }}>
              <CustomButton
                className={styles.inwardButton}
                style={{ borderRadius: '5px' }}
                onClick={() => history.push('/ipa-preperation-log')}
              >
                Back
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                htmlType="button" // Change from submit to button
                type="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => setIsModalVisible(true)} // Show modal on click
              >
                Initiate Verification
              </CustomButton>

              {isModalVisible ? (
                <CustomModal
                  title="Verification"
                  visible={isModalVisible}
                  width="50%"
                  footer={null}
                  className={styles.customModal}
                  closable={false}
                >
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={24}>
                      <Col span={12}>
                        <FormItem
                          label="User ID"
                          name="emp_id"
                          rules={[
                            {
                              required: true,
                              message: 'Enter User ID'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input placeholder="Enter User ID" />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Password'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input.Password placeholder="Enter Password" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Comments"
                          name="comments"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Comments'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <TextArea placeholder="Enter Comments" />
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem className="text-center">
                      <Space>
                        <CustomButton
                          className={styles.inwardButton}
                          onClick={handleClose}
                          type="primary"
                          size="small"
                          ghost
                        >
                          Close
                        </CustomButton>
                        <CustomButton
                          className={styles.inwardButton}
                          htmlType="submit"
                          type="primary"
                          size="small"
                        >
                          Verify
                        </CustomButton>
                      </Space>
                    </FormItem>
                  </Form>
                </CustomModal>
              ) : (
                ''
              )}
            </Space>
          </FormItem>
        </Card>
      </Form>
    </Layout>
  );
};

export default CreateIPAPreperationLog;
