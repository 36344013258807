import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  message,
  Select,
  DatePicker
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import {
  getAllSuppliers,
  updateVendor,
  getAllApprovedMaterials,
  getAllMaterials,
  createVendor,
  createMaterial,
  getAllMaterialsTypes,
  getAllUoms
} from '../../redux/slice';
import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import moment from 'moment';
// import CustomTable from 'common/Content/CustomTable';
import { useLocation } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import CustomModal from '@/common/CustomModal';

const { TextArea } = Input;
const dateFormat = 'Y-M-D';

const ViewVendor = ({ loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const [materials, setMaterials] = useState({});
  const [status, setStatus] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  // const [code, setCode] = useState(false);
  const [search, setSearch] = useState('');
  const [materialslist, setMaterialslist] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [uoms, setUoms] = useState([]);

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetdata();
    handleGetAllSuppliersList();
    handleGetAllUoms();
  }, [search]);

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterials(payload)).then((response) => {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        setMaterialslist(response?.payload?.data);
      }
    });
  };

  const handleGetAllUoms = () => {
    let payload = {};
    dispatch(getAllUoms(payload)).then((response) => {
      if (response?.payload?.success) {
        setUoms(response?.payload?.data);
      }
    });
  };

  const handleGetAllSuppliersList = () => {
    let payload = {};
    dispatch(getAllMaterialsTypes(payload)).then((response) => {
      if (response?.payload?.success) {
        setSuppliers(response?.payload?.data);
      }
    });
  };

  const handleGetdata = () => {
    let payload = { id: searchParams.get('id') };
    searchParams.get('type') == 'vendor'
      ? dispatch(getAllSuppliers(payload)).then((response) => {
          if (response?.payload?.success) {
            console.log(response?.payload?.data);
            setMaterials(response?.payload?.data);
          }
        })
      : dispatch(getAllApprovedMaterials(payload)).then((response) => {
          if (response?.payload?.success) {
            console.log(response?.payload?.data);
            setMaterials(response?.payload?.data);
          }
        });
  };
  useEffect(() => {
    form.setFieldsValue({
      material_code:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.code
          : materials?.code,
      material_name:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.name
          : materials?.name,
      uom:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.uom
          : materials?.uom,
      type:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.item
          : materials?.item,
      weighingrules:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.weighingrules
          : materials?.weighingrules,
      temparature:
        searchParams.get('type') == 'vendor'
          ? materials?.material?.temp
          : materials?.temp,
      manufacturer_name:
        searchParams.get('type') == 'vendor'
          ? materials?.manufacturer_name
          : materials?.suppliers?.[0]?.manufacturer_name,
      suppliers_name:
        searchParams.get('type') == 'vendor'
          ? materials?.name
          : materials?.suppliers?.[0]?.name,
      manufacturer_address:
        searchParams.get('type') == 'vendor'
          ? materials?.manufacturer_address
          : materials?.suppliers?.[0]?.manufacturer_address,
      supplier_address:
        searchParams.get('type') == 'vendor'
          ? materials?.address1
          : materials?.suppliers?.[0]?.address1,
      requalification_due:
        searchParams.get('type') == 'vendor'
          ? moment(materials?.requalification_due, dateFormat)
          : moment(materials?.suppliers?.[0]?.requalification_due, dateFormat),
      status:
        searchParams.get('type') == 'vendor'
          ? materials?.vendor_status
          : materials?.suppliers?.[0]?.vendor_status,
      tollerence:
        searchParams.get('type') == 'vendor'
          ? materials?.tolerance_months
          : materials?.suppliers?.[0]?.tolerance_months
    });
  }, [materialslist]);

  const handleStatus = (status) => {
    const payload = { status: status, supplier_id: searchParams.get('id') };
    dispatch(updateVendor(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        setIsModalVisible(false);
        searchParams.get('type') == 'vendor'
          ? history.push('/vendors')
          : history.push('/material-list');
      }
    });
  };
  const onFinish = (values) => {
    console.log(values);

    const payload =
      status == 'updated'
        ? {
            id: searchParams.get('id'),
            code: values.material_code,
            name: values?.suppliers_name,
            vendor_status: values?.status,
            address1: values?.supplier_address,
            manufacturer_name: values?.manufacturer_name,
            manufacturer_address: values?.manufacturer_address,
            requalification_due: values?.requalification_due,
            tolerance_months: values?.tollerence,
            temp: values?.temparature,
            weighingrules: values?.weighingrules,
            type: values?.type,
            uom: values?.uom
            // values?.type == 'API'
            //   ? 1
            //   : values?.type == 'RM'
            //   ? 2
            //   : values?.type == 'PM' && values?.uom == "No's"
            //   ? 3
            //   : (values?.type == 'PM' && values?.uom == 'KG') ||
            //     (values?.type == 'PM' && values?.uom == 'MT')
            //   ? 2
            //   : values?.uom == 'LT'
            //   ? 3
            //   : ''
          }
        : searchParams.get('type') == 'vendor'
        ? { status: status, supplier_id: searchParams.get('id') }
        : { status: status, material_id: searchParams.get('id') };

    searchParams.get('type') == 'vendor' && status == 'updated'
      ? dispatch(createVendor(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            history.push('/vendors');
          }
        })
      : searchParams.get('type') == 'material' && status == 'updated'
      ? dispatch(createMaterial(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            history.push('/material-list');
          }
        })
      : dispatch(updateVendor(payload)).then((response) => {
          if (response?.payload?.success) {
            message.success(response?.payload?.message);
            searchParams.get('type') == 'vendor'
              ? history.push('/vendors')
              : history.push('/material-list');
          }
        });
  };

  const onChange = (value) => {
    console.log(value);
    const material = materialslist.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    form.setFieldsValue({ material_name: material?.[0]?.name });
    form.setFieldsValue({ uom: material?.[0]?.uom });
    form.setFieldsValue({ type: material?.[0]?.item });
    form.setFieldsValue({ temparature: material?.[0]?.temp });
    // setCode(material[0]);
  };

  const handleVendors = () => {
    setStatus('Approved');
    setIsModalVisible(true);
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };
  // const columns = [
  //   {
  //     title: 'Supplier Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Manufacturer Name',
  //     dataIndex: 'manufacturer_name',
  //     key: 'manufacturer_name',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Material Code',
  //     dataIndex: 'code',
  //     key: 'code',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Material Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'qa_status',
  //     key: 'qa_status',
  //     ellipsis: true,
  //     width: '100px'
  //   },
  //   {
  //     title: 'Re-qualification Due',
  //     dataIndex: 'requalification_due',
  //     key: 'requalification_due',
  //     ellipsis: true,
  //     width: '100px',
  //     render: (_, record) => {
  //       return record?.requalification_due
  //         ? moment(record?.requalification_due).format('DD MMM YYYY')
  //         : 'NA';
  //     }
  //   }
  // ];

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Card
          title="MATERIAL DETAILS"
          className={styles.CustomPanel}
          extra={
            user?.level == 2 || Superadmindata?.[0]?.level == 2 ? (
              <EditOutlined onClick={() => setIsEdit(true)} />
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8} md={24} sm={24}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: true,
                    message: 'Select material code'
                  }
                ]}
                className={styles.customLable}
              >
                {searchParams.get('type') == 'vendor' ? (
                  <Select
                    showSearch
                    placeholder="Select a material code"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    disabled
                  >
                    {materialslist?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.code}
                        </option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input
                    disabled={
                      searchParams.get('type') == 'vendor' ? true : !isEdit
                    }
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} xl={6} span={8} md={24} sm={24}>
              <FormItem
                label="Name of the Material"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.material?.name
                  //     : materials?.name
                  // }
                  disabled={
                    searchParams.get('type') == 'vendor' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={4} md={24} sm={24}>
              <FormItem
                label="Type of Material"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Enter name of the material type'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Material Type">
                  {suppliers?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.material_type}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={4} span={6} md={24} sm={24}>
              <FormItem
                label="Weighing Rules"
                name="weighingrules"
                rules={[
                  {
                    required: true,
                    message: 'Select weighing rules'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Material Type"
                  disabled={
                    searchParams.get('type') == 'vendor' ? true : !isEdit
                  }
                >
                  <option value={3}>No Weighing</option>
                  <option value={2}>Root n+1</option>
                  <option value={1}>100% Weighing</option>
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={2} span={2} md={24} sm={24}>
              <FormItem
                label="UOM"
                name="uom"
                rules={[
                  {
                    required: true,
                    message: 'Enter UOM'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select UOM">
                  {uoms?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.uom_type}
                      </option>
                    );
                  })}
                  {/* <option value="KG">KG</option>
                  <option value="MT">MT</option>
                  <option value="No's">{`No's`}</option>
                  <option value="LT">LT</option> */}
                </Select>
                {/* <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.material?.uom
                  //     : materials?.uom
                  // }
                  disabled={
                    searchParams.get('type') == 'vendor' ? true : !isEdit
                  }
                /> */}
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Name"
                name="manufacturer_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.manufacturer_name
                  //     : materials?.suppliers?.[0]?.manufacturer_name
                  // }

                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Suppliers Name"
                name="suppliers_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.name
                  //     : materials?.suppliers?.[0]?.name
                  // }
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Manufacturer Address"
                name="manufacturer_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Manufacturer Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.manufacturer_address
                  //     : materials?.suppliers?.[0]?.manufacturer_address
                  // }
                  rows={4}
                  placeholder="Enter Manufacturer Address"
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Suppliers Address"
                name="supplier_address"
                rules={[
                  {
                    required: true,
                    message: 'Enter Suppliers Address'
                  }
                ]}
                className={styles.customLable}
              >
                <TextArea
                  rows={4}
                  placeholder="Enter suppliers Address"
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.address1
                  //     : materials?.suppliers?.[0]?.address1
                  // }
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Requalification Due"
                name="requalification_due"
                rules={[
                  {
                    required: true,
                    message: 'Enter Requalification Due'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.requalification_due
                  //       ? moment(materials?.requalification_due).format(
                  //           'DD MMM YYYY'
                  //         )
                  //       : 'NA'
                  //     : materials?.suppliers?.[0]?.requalification_due
                  //     ? moment(
                  //         materials?.suppliers?.[0]?.requalification_due
                  //       ).format('DD MMM YYYY')
                  //     : 'NA'
                  // }
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Vendor Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Enter Vendor Status'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Status"
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                >
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="partially_approved">Partially Approved</option>
                </Select>
                {/* <Input 
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.vendor_status
                  //     : materials?.suppliers?.[0]?.vendor_status
                  // }
                //   disabled={!isEdit}
                // />*/}
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Tollerence Months"
                name="tollerence"
                rules={[
                  {
                    required: true,
                    message: 'Enter Tollerence Months'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.tolerance_months
                  //     : materials?.suppliers?.[0]?.tolerance_months
                  // }
                  disabled={
                    searchParams.get('type') == 'material' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Storage Tempareture Condition"
                name="temparature"
                rules={[
                  {
                    required: true,
                    message: 'Enter Storage Temareture Condition'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  // value={
                  //   searchParams.get('type') == 'vendor'
                  //     ? materials?.material?.temp
                  //     : materials?.temp
                  // }
                  disabled={
                    searchParams.get('type') == 'vendor' ? true : !isEdit
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            {user?.level == 2 || Superadmindata?.[0]?.level == 2 ? (
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: '100%' }}
                onClick={() => setStatus('updated')}
              >
                Update
              </CustomButton>
            ) : (
              <>
                {searchParams.get('type') == 'vendor' ? (
                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    onClick={() => handleVendors()}
                    style={{ width: '100%' }}
                  >
                    Approve
                  </CustomButton>
                ) : (
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    // onClick={() => handleVendors()}
                    onClick={() => setStatus('Approved')}
                    style={{ width: '100%' }}
                  >
                    Approve
                  </CustomButton>
                )}
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setStatus('Rejected')}
                  style={{ width: '100%' }}
                >
                  Reject
                </CustomButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setStatus('OnHold')}
                  style={{ width: '100%' }}
                >
                  Hold
                </CustomButton>
              </>
            )}
            <CustomButton
              htmlType="button"
              onClick={() => history.back()}
              style={{ width: '100%' }}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
        {isModalVisible ? (
          <CustomModal
            title="Confirm?"
            visible={isModalVisible}
            width="50%"
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <FormItem>
              <h5>
                Would you like to Approve the batch or generate a Test Report
                Form (TRF) to place the batch in Quarantine?
              </h5>
            </FormItem>

            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => handleClose()}
                  type="primary"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => handleStatus('Approved')}
                  style={{ width: '100%' }}
                >
                  Approve
                </CustomButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => handleStatus('TRF')}
                  style={{ width: '100%' }}
                >
                  Generate TRF
                </CustomButton>
              </Space>
            </FormItem>
            {/* <FormItem className="text-center"> */}

            {/* </FormItem> */}
          </CustomModal>
        ) : (
          ''
        )}
      </Form>

      {/* <br />
      <Card
        title={<span>Existing Vendors</span>}
        className={`${styles.cardStyles_approver}`}
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            // dataSource={suppliersdata?.suppliers}
            loading={loading}
          />
        </div>
      </Card> */}
    </Layout>
  );
};
export default ViewVendor;
