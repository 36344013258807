import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Card,
  Space,
  Col,
  Checkbox,
  Radio,
  Select,
  Collapse,
  Divider,
  Input
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  HolderOutlined,
  CopyOutlined,
  // PlusCircleOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
// import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import {
  getAllProcessList,
  getMaterials,
  createbomMateials
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Panel } = Collapse;

const ConfigGranulation = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const [processList, setProcessList] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [process, setProcess] = useState([]);
  const [processgroup, setProcessgroup] = useState([]);
  const [filterData, setFilterData] = useState([]);
  // // const [equipments, setEquipments] = useState([
  //   { Equipment_id: '', model: '' }
  // ]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllProcessList();
    handleGetAllMaterials();
  }, []);

  const handleGetAllProcessList = () => {
    dispatch(getAllProcessList()).then((res) => {
      if (res?.payload?.success) {
        res?.payload?.data?.map((item) => {
          item?.bom_process_masters?.map((data) => {
            data.equipments = [];
            data.equipments.push({
              Equipment_id: '',
              eqpmodel: '',
              model: ''
            });
          });
        });
        setProcessList(res?.payload?.data);
      }
    });
  };

  const handleGetAllMaterials = () => {
    const payload = { id: params.id };
    dispatch(getMaterials(payload)).then((res) => {
      if (res?.payload?.success) {
        setMaterials(res?.payload?.data?.bmr_product_materials);
      }
    });
  };

  //   const onChange = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };

  const onFinish = () => {
    let config_materials = [];
    let data = processList.filter((process) => process.type);
    console.log(data);
    data?.map((item) => {
      const master = item?.bom_process_masters?.filter((bom) => bom.type);
      config_materials.push({
        process_group_id: item?.id,
        group_mod_name: item?.updatedProcessgroup,
        process_master: master
      });
    });
    const payload = { product_id: params.id, process_group: config_materials };

    dispatch(createbomMateials(payload)).then((res) => {
      if (res?.payload?.success) {
        history.push(`/bmr-params/${params.id}`);
      }
    });
  };

  const handleChangegroup = (id, index) => {
    console.log(id);
    // if (!processgroup.includes(id)) {
    setProcessgroup([...processgroup, id]);
    processList[index]['type'] = true;
    processList[index]['process_group_id'] = id;
    // } else {
    //   //   console.log(process.indexOf(id));
    //   const newdata = [...processgroup];
    //   newdata.splice(processgroup.indexOf(id), 1);
    //   processList[index]['type'] = false;
    //   setProcessgroup(newdata);
    // }
  };

  const handleChangeProcess = (id, i, index) => {
    console.log(id);
    // if (!process.includes(id)) {
    setProcess([...process, id]);
    processList[index]['bom_process_masters'][i]['type'] = true;
    processList[index]['bom_process_masters'][i]['process_id'] = id;
    // } else {
    //   //   console.log(process.indexOf(id));
    //   const newdata = [...process];
    //   newdata.splice(process.indexOf(id), 1);
    //   processList[index]['bom_process_masters'][i]['type'] = false;
    //   setProcess(newdata);
    // }
  };

  const AddEquipments = (e, i, index) => {
    console.log(e, i, index);
    let list = [...processList];
    list[index]['bom_process_masters'][i]['equipments'].push({
      Equipment_id: '',
      eqpmodel: '',
      model: ''
    });
    setProcessList(list);
    // setEquipments([...equipments, { Equipment_id: '', model: '' }]);
  };

  const deleteEquipments = (e, i, index) => {
    console.log(e);
    let list = [...processList];
    list[index]['bom_process_masters'][i]['equipments'].splice(index, 1);
    setProcessList(list);
  };
  const handleChangeMaterials = (e, i, index) => {
    const newdata = [...processList];
    console.log(e, index);
    newdata[index]['bom_process_masters'][i]['material_items'] = e;
    setProcess(newdata);
  };

  const handleChangeModel = (e, i, index, l, type) => {
    if (type == 'Equipment_id') {
      const Filtered_data = processList[index]['equipment_masters'].filter(
        (item) => item?.id === e
      );
      setFilterData(Filtered_data);
    }
    console.log(e);
    // if (type == 'Equipment_id') {
    let list = [...processList];
    if (
      list[index]['bom_process_masters'][i]['equipments'][l][type] == 'eqpmodel'
    ) {
      list[index]['bom_process_masters'][i]['equipments'][l][type] = e;
    } else {
      list[index]['bom_process_masters'][i]['equipments'][l][type] = e;
    }

    setProcessList(list);
    // } else if (type == 'model') {
    //   const newdata = [...equipments];
    //   newdata[l]['model'] = e;
    // }
    // console.log(e, index);
    // if (type == 'Equipment_id') {
    //   newdata[index]['bom_process_masters'][i]['Equipments'][l][
    //     'equipment_id'
    //   ] = e;
    // }

    // setProcess(newdata);
  };

  // const handleChangEquipemts = (e, i, index) => {
  //   console.log(e, i, index);
  //   const Filtered_data = processList[index]['equipment_masters'].filter(
  //     (item) => item?.id === e
  //   );
  //   setFilterData(Filtered_data);
  //   console.log(Filtered_data);
  // };

  console.log(process);
  const handleSetMaterial = (e, i, index) => {
    const newdata = [...processList];
    console.log(e);
    e.target.value == 1
      ? (newdata[index]['bom_process_masters'][i]['material_level'] = true)
      : (newdata[index]['bom_process_masters'][i]['material_level'] = false);
    setProcessList(newdata);
  };

  const handleSetLots = (e, i, index) => {
    const newdata = [...processList];
    console.log(e);
    newdata[index]['bom_process_masters'][i]['lots'] = e.target.value;
    setProcessList(newdata);
  };

  const handleClonegroup = (e, index) => {
    e.stopPropagation();
    let getgroup_data = processList[index];

    let process_masters = [];
    getgroup_data.bom_process_masters.map((process) => {
      process_masters.push({
        bom_process_id: process?.bom_process_id,
        equipments: [{ Equipment_id: '', eqpmodel: '', model: '' }],
        id: process.id,
        is_active: true,
        process_code: null,
        process_group_seq: process.process_group_seq,
        process_name: process.process_name,
        process_seq: process.process_seq
      });
    });
    let clone_data = {
      bom_process_masters: process_masters,
      equipment_masters: getgroup_data.equipment_masters,
      process_group_name: getgroup_data.process_group_name,
      id: getgroup_data.id,
      is_active: true
    };
    // console.log(getgroup_data);
    let list = [...processList];
    let numbersCopy = JSON.parse(JSON.stringify(list));
    // console.log(numbersCopy);
    numbersCopy.push(clone_data);
    setProcessList(numbersCopy);
  };

  const handleClonesubgroup = (index, i) => {
    let getgroup_data = processList[index]['bom_process_masters'][i];
    let list = [...processList];
    let numbersCopy = JSON.parse(JSON.stringify(list));
    numbersCopy[index]['bom_process_masters'].push(getgroup_data);
    setProcessList(numbersCopy);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    console.log(droppedItem);
    if (droppedItem.type === 'dropableItem') {
      let updatedList = [...processList];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      console.log(reorderedItem);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      setProcessList(updatedList);
      console.log(droppedItem);
    }
    // if (droppedItem.type === 'dropableItem1')
    else {
      console.log(droppedItem.destination);
      let updatedList = [...processList];
      console.log(updatedList);
      const [reorderedItem] = updatedList[
        droppedItem.type
      ].bom_process_masters.splice(droppedItem.source.index, 1);
      console.log(reorderedItem);
      updatedList[droppedItem.type].bom_process_masters.splice(
        droppedItem.destination.index,
        0,
        reorderedItem
      );
      setProcessList(updatedList);
    }
  };

  const handlechangeProcessGroup = (e, index) => {
    let list = [...processList];
    list[index]['updatedProcessgroup'] = e.target.value;
    setProcessList(list);
  };

  const handlechangeProcessName = (e, i, index) => {
    let list = [...processList];
    list[index]['bom_process_masters'][i]['process_mod_name'] = e.target.value;
    setProcessList(list);
  };
  // const handleDropSubpanel = (droppedItem, index) => {
  //   if (!droppedItem.destination) return;
  //   var updatedList = [...processList];

  //   const [reorderedItem] = updatedList[index].bom_process_masters.splice(
  //     droppedItem.source.index,
  //     1
  //   );
  //   console.log(reorderedItem);
  //   updatedList[index].bom_process_masters.splice(
  //     droppedItem.destination.index,
  //     0,
  //     reorderedItem
  //   );
  //   setProcessList(updatedList);
  //   // console.log(droppedItem);
  // };

  const location = useLocation();
  const data = location.state;

  // console.log('Data:', data.data);

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h4
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            marginLeft: '9px',
            color: '#c91761'
          }}
          className={styles.dividerstyles}
        >
          Configure BMR Process
          <Divider />
        </h4>

        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId="list-container"
            type="dropableItem"
            className={`${styles.approver_line}`}
          >
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {processList
                  ?.sort((a, b) => {
                    const aIsChecked = data?.data?.some(
                      (hi) => hi.process_group_id === a.id
                    );
                    const bIsChecked = data?.data?.some(
                      (hi) => hi.process_group_id === b.id
                    );
                    return aIsChecked === bIsChecked ? 0 : aIsChecked ? -1 : 1;
                  })
                  .map((item, index) => {
                    const isDisabled = data?.data?.some(
                      (hi) => hi.process_group_id === item.id
                    );

                    return (
                      <>
                        {console.log('Is Drag disabled: ', isDisabled)}
                        <Draggable
                          key={item.id}
                          draggableId={`${
                            parseInt(processList[processList.length - 1].id) +
                            index
                          }`}
                          index={index}
                          isDragDisabled={isDisabled}
                        >
                          {(provided) => (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Collapse
                                  collapsible="icon"
                                  defaultActiveKey={['0']}
                                  className={styles.CustomPanel1}
                                >
                                  <Panel
                                    header={
                                      <Space>
                                        <HolderOutlined />
                                        <Checkbox
                                          checked={isDisabled}
                                          disabled={isDisabled}
                                          onChange={() =>
                                            handleChangegroup(item?.id, index)
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {item?.process_group_name}
                                        </Checkbox>
                                      </Space>
                                    }
                                    className={styles.CustomPanel2}
                                    key={index}
                                    extra={
                                      <Space>
                                        <Input
                                          placeholder="Enter Process Group Name of your Choice"
                                          className={styles.updateProcess}
                                          onChange={(e) =>
                                            handlechangeProcessGroup(e, index)
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                          disabled={isDisabled}
                                        />
                                        {/* </FormItem> */}
                                        <CustomButton
                                          type="primary"
                                          ghost
                                          onClick={(e) =>
                                            handleClonegroup(e, index)
                                          }
                                          size={'small'}
                                          style={{ fontSize: '13px' }}
                                        >
                                          <CopyOutlined /> Clone
                                        </CustomButton>
                                      </Space>
                                    }
                                  >
                                    {/* <DragDropContext
                                    onDragEnd={(e) =>
                                      handleDropSubpanel(e, index)
                                    }
                                  > */}
                                    <Droppable
                                      droppableId={item.id}
                                      className={`${styles.approver_line}`}
                                      type={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="list-container"
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                        >
                                          {item?.bom_process_masters?.map(
                                            (param, i) => {
                                              return (
                                                <Draggable
                                                  key={
                                                    parseInt(param.id) +
                                                    parseInt(i) +
                                                    param?.process_name
                                                  }
                                                  draggableId={
                                                    parseInt(param.id) +
                                                    parseInt(i) +
                                                    param?.process_name
                                                  }
                                                  index={i}
                                                >
                                                  {(provided) => (
                                                    <>
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                      >
                                                        <>
                                                          <Card
                                                            key={i}
                                                            title={
                                                              <Space>
                                                                <HolderOutlined />
                                                                <Checkbox
                                                                  onChange={() =>
                                                                    handleChangeProcess(
                                                                      param?.id,
                                                                      i,
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  {
                                                                    param?.process_name
                                                                  }
                                                                </Checkbox>
                                                              </Space>
                                                            }
                                                            className={
                                                              styles.SubPanel
                                                            }
                                                            extra={
                                                              <Space>
                                                                <Input
                                                                  placeholder="Enter Process Name of your Choice"
                                                                  className={
                                                                    styles.updateProcess
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handlechangeProcessName(
                                                                      e,
                                                                      i,
                                                                      index
                                                                    )
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    e.stopPropagation()
                                                                  }
                                                                />
                                                                <CustomButton
                                                                  type="primary"
                                                                  ghost
                                                                  onClick={() =>
                                                                    handleClonesubgroup(
                                                                      index,
                                                                      i
                                                                    )
                                                                  }
                                                                  size={'small'}
                                                                  style={{
                                                                    fontSize:
                                                                      '13px'
                                                                  }}
                                                                >
                                                                  <CopyOutlined />{' '}
                                                                  Clone
                                                                </CustomButton>
                                                              </Space>
                                                            }
                                                          >
                                                            <>
                                                              <Row gutter={24}>
                                                                <Col span={10}>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        '13px',
                                                                      color:
                                                                        '#263238'
                                                                      // marginTop: '7px'
                                                                    }}
                                                                  >
                                                                    Would you
                                                                    like to
                                                                    include
                                                                    parameters
                                                                    at Batch
                                                                    Level or Lot
                                                                    Level?
                                                                  </p>
                                                                </Col>
                                                                <Col span={14}>
                                                                  <Radio.Group
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleSetMaterial(
                                                                        e,
                                                                        i,
                                                                        index
                                                                      )
                                                                    }
                                                                  >
                                                                    <Radio
                                                                      value={1}
                                                                    >
                                                                      Batch
                                                                    </Radio>
                                                                    <Radio
                                                                      value={2}
                                                                    >
                                                                      Lot
                                                                    </Radio>
                                                                  </Radio.Group>
                                                                </Col>
                                                              </Row>
                                                              {param?.material_level ==
                                                              false ? (
                                                                <Row>
                                                                  <Col
                                                                    span={12}
                                                                  >
                                                                    <FormItem
                                                                      label="Lots"
                                                                      rules={[
                                                                        {
                                                                          required: false,
                                                                          message:
                                                                            'Select Material'
                                                                        }
                                                                      ]}
                                                                      className={
                                                                        styles.customLable
                                                                      }
                                                                    >
                                                                      <Input
                                                                        placeholder="Enter how many lots"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleSetLots(
                                                                            e,
                                                                            i,
                                                                            index
                                                                          )
                                                                        }
                                                                      />
                                                                    </FormItem>
                                                                  </Col>
                                                                </Row>
                                                              ) : (
                                                                ''
                                                              )}
                                                              <Row>
                                                                <Col span={24}>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        '13px',
                                                                      color:
                                                                        '#263238'
                                                                      // marginTop: '7px'
                                                                    }}
                                                                  >
                                                                    Add
                                                                    materials
                                                                    used during
                                                                    this process
                                                                  </p>
                                                                </Col>
                                                              </Row>

                                                              <Row gutter={24}>
                                                                <Col span={24}>
                                                                  <FormItem
                                                                    //   label="Materials"
                                                                    rules={[
                                                                      {
                                                                        required: false,
                                                                        message:
                                                                          'Select Material'
                                                                      }
                                                                    ]}
                                                                    className={
                                                                      styles.customLable
                                                                    }
                                                                  >
                                                                    <Select
                                                                      mode="multiple"
                                                                      allowClear
                                                                      placeholder="Select Materials"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeMaterials(
                                                                          e,
                                                                          i,
                                                                          index
                                                                        )
                                                                      }
                                                                    >
                                                                      {materials?.map(
                                                                        (
                                                                          mats,
                                                                          j
                                                                        ) => {
                                                                          return (
                                                                            <option
                                                                              key={
                                                                                j
                                                                              }
                                                                              value={
                                                                                mats
                                                                                  ?.material
                                                                                  ?.id
                                                                              }
                                                                            >
                                                                              {
                                                                                mats
                                                                                  ?.material
                                                                                  ?.name
                                                                              }
                                                                            </option>
                                                                          );
                                                                        }
                                                                      )}
                                                                      <option
                                                                        value={
                                                                          1281
                                                                        }
                                                                      >
                                                                        Output
                                                                        of
                                                                        previous
                                                                        Stage
                                                                        (In
                                                                        process)
                                                                      </option>
                                                                    </Select>
                                                                  </FormItem>
                                                                </Col>
                                                              </Row>
                                                              <Row>
                                                                <Col span={4}>
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        '13px',
                                                                      color:
                                                                        '#263238'
                                                                      // marginTop: '7px'
                                                                    }}
                                                                  >
                                                                    Select the
                                                                    Equipments
                                                                  </p>
                                                                </Col>
                                                              </Row>
                                                              {param?.equipments?.map(
                                                                (
                                                                  equipment,
                                                                  l
                                                                ) => {
                                                                  return (
                                                                    <Row
                                                                      gutter={
                                                                        24
                                                                      }
                                                                      key={l}
                                                                    >
                                                                      <Col
                                                                        span={6}
                                                                      >
                                                                        <FormItem
                                                                          // label="Select the Equipments"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Equipments'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Select
                                                                            // mode="multiple"

                                                                            allowClear
                                                                            placeholder="Select Equipments"
                                                                            value={
                                                                              equipment?.Equipment_id ||
                                                                              null
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeModel(
                                                                                e,
                                                                                i,
                                                                                index,
                                                                                l,
                                                                                'Equipment_id'
                                                                              )
                                                                            }
                                                                          >
                                                                            {item?.equipment_masters?.map(
                                                                              (
                                                                                mats,
                                                                                j
                                                                              ) => {
                                                                                return (
                                                                                  <option
                                                                                    key={
                                                                                      j
                                                                                    }
                                                                                    value={
                                                                                      mats?.id
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      mats?.equipment_name
                                                                                    }
                                                                                  </option>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </Select>
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={
                                                                          10
                                                                        }
                                                                      >
                                                                        <FormItem
                                                                          // label="Select the Equipments"
                                                                          rules={[
                                                                            {
                                                                              required: false,
                                                                              message:
                                                                                'Select Equipment ID'
                                                                            }
                                                                          ]}
                                                                          className={
                                                                            styles.customLable
                                                                          }
                                                                        >
                                                                          <Select
                                                                            mode="multiple"
                                                                            // value={
                                                                            //   equipment?.eqpmodel ||
                                                                            //   null
                                                                            // }
                                                                            allowClear
                                                                            placeholder="Select Equipment ID"
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleChangeModel(
                                                                                e,
                                                                                i,
                                                                                index,
                                                                                l,
                                                                                'eqpmodel'
                                                                              )
                                                                            }
                                                                          >
                                                                            {filterData[0]?.equipment_models?.map(
                                                                              (
                                                                                mats,
                                                                                j
                                                                              ) => {
                                                                                return (
                                                                                  <option
                                                                                    key={
                                                                                      j
                                                                                    }
                                                                                    value={
                                                                                      mats?.id
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      mats?.equipment_id
                                                                                    }
                                                                                  </option>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </Select>
                                                                        </FormItem>
                                                                      </Col>
                                                                      <Col
                                                                        span={6}
                                                                      >
                                                                        {l ==
                                                                        0 ? (
                                                                          <CustomButton
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              AddEquipments(
                                                                                e,
                                                                                i,
                                                                                index
                                                                              )
                                                                            }
                                                                          >
                                                                            <PlusOutlined />
                                                                          </CustomButton>
                                                                        ) : (
                                                                          <CustomButton
                                                                            // className={styles.inwardButton}
                                                                            // type="primary"
                                                                            // ghost
                                                                            onClick={(
                                                                              e
                                                                            ) =>
                                                                              deleteEquipments(
                                                                                e,
                                                                                i,
                                                                                index
                                                                              )
                                                                            }
                                                                          >
                                                                            <DeleteOutlined />
                                                                          </CustomButton>
                                                                        )}
                                                                      </Col>
                                                                    </Row>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          </Card>
                                                          <br />
                                                        </>
                                                      </div>
                                                    </>
                                                  )}
                                                </Draggable>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                    </Droppable>
                                    {/* </DragDropContext> */}
                                  </Panel>
                                </Collapse>
                              </div>
                            </>
                          )}
                        </Draggable>

                        <br />
                      </>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <br />
        <FormItem className="text-center">
          <Space>
            {/* {truckShow ? (
              <Image
                src={truck}
                preview={false}
                style={{ width: '100px !important', height: '100px' }}
              />
            ) : ( */}
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
            <CustomButton
              className={styles.inwardButton}
              htmlType="submit"
              type="primary"
            >
              Proceed
            </CustomButton>
            {/* )} */}
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default ConfigGranulation;
