import apiClient from 'utilities/apiClient';

export const create_dms = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.dispense}/${url}`,
    payload,
    true,
    'file'
  );
};
export const create_file_dms = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const get_batches = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};
