import React from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomModal from '@/common/CustomModal';
import CustomButton from '@/common/CustomButton';
// import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { returnStockQty } from '../../redux/slice';
// import { updateProduct } from '@/features/dispensing/redux/slice';
// import { updateprintIssue } from '@/features/dispensing/redux/slice';
// import { updateQCapprove } from '@/features/qa/redux/slice';
// import { updateSampling } from '@/features/samplingMaterials/redux/slice';
// import {
//   createProdClearence,
//   createQAClearence
// } from '@/features/prodUser/redux/slice';
// import { history } from 'app/history';

const { TextArea } = Input;

const DeleteMaterialsModel = ({
  isModal,
  //   materials,
  setIsDelete,
  setIsConfirm,
  isConfirm,
  stockBatches,
  setIsVerify,
  isVerify,
  stockMatID,
  dispenseRequestId,
  setNewStockBatches,
  newStockBatches,
  handleGetAllMaterials,
  type
  // setEdit
}) => {
  const dispatch = useDispatch();
  console.log(stockMatID, 'stockMatID');
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const payload = {
      employee_id: values?.employee_id,
      password: values?.password,
      comments: values?.comments,
      dispensing_request_id: dispenseRequestId,
      material_id: type === 'auto' ? stockMatID?.material_id : stockMatID,
      material_lot_id: type === 'auto' ? stockMatID?.material_lot_id : '',
      material_part_id:
        type === 'auto'
          ? stockMatID?.material_part_id
            ? stockMatID?.material_part_id
            : ''
          : '',
      batch_qty: newStockBatches
    };
    dispatch(returnStockQty(payload)).then((response) => {
      if (response?.payload?.success) {
        setIsDelete(false);
        handleGetAllMaterials();
        // window.location.reload();
      }
    });
    console.log(payload);
  };

  const handleClose = () => {
    setIsDelete(false);
  };
  console.log(stockBatches, 'stockBatches');
  let batch_qty = [];
  let sum_of_net = 0;
  stockBatches?.map((batch) => {
    batch?.sub_batches?.map((item) => {
      batch?.dispenses?.map((item1) => {
        sum_of_net += parseFloat(item1?.net_weight);
      });
      batch_qty.push({
        batch_details_id: item.batch_id,
        batch_number: item.batch_number,
        stock_qty: '',
        sum_of_qty: sum_of_net,
        remarks: ''
      });
    });
    sum_of_net = 0;
  });

  const handleStock = (e, id, index, type) => {
    setNewStockBatches(batch_qty);
    console.log(e.target.value, id, index);
    const list = [...newStockBatches];
    list[index][type] = e.target.value;
    setNewStockBatches(list);
  };
  console.log(batch_qty, 'batch_qty');
  return (
    <CustomModal
      title="Delete Material"
      visible={isModal}
      width="50%"
      footer={null}
      className={styles.customModal}
      closable={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <p>Are you sure you want to delete this Material?</p>
        {!isConfirm ? (
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                onClick={handleClose}
                type="primary"
                size="small"
                ghost
              >
                No
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                //   htmlType="submit"
                onClick={() => setIsConfirm(true)}
                type="primary"
                size="small"
              >
                Yes
              </CustomButton>
            </Space>
          </FormItem>
        ) : (
          ''
        )}
        {isConfirm ? (
          <>
            {batch_qty?.map((batch, index) => {
              console.log(batch, 'batch12345');
              return (
                <Row gutter={24} key={index}>
                  <Col span={6}>
                    <FormItem
                      label="Batch Number"
                      //   name="emplobatchyee_id"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Batch Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Batch Number"
                        value={batch?.batch_number}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Stock Qty"
                      //   name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Stock Qty'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Stock qty"
                        value={batch?.sum_of_qty}
                        disabled
                        onChange={(e) =>
                          handleStock(e, batch?.batch_id, index, 'stock_qty')
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Remarks"
                      //   name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Remarks'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Remarks"
                        onChange={(e) =>
                          handleStock(e, batch?.batch_id, index, 'remarks')
                        }
                      />
                    </FormItem>
                  </Col>

                  {/* <Col span={8}>
                    <FormItem
                      label="Remarks"
                      //   name="comments"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea placeholder="Enter Comments" />
                    </FormItem>
                  </Col> */}
                </Row>
              );
            })}
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  No
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  //   htmlType="submit"
                  onClick={() => setIsVerify(true)}
                  type="primary"
                  size="small"
                >
                  Intiate Verification
                </CustomButton>
              </Space>
            </FormItem>
          </>
        ) : (
          ''
        )}
        {isVerify ? (
          <>
            {' '}
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="User ID"
                  name="employee_id"
                  rules={[
                    {
                      required: true,
                      message: 'Enter User ID'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input placeholder="Enter User ID" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Password'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input.Password placeholder="Enter Password" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Comments'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <TextArea placeholder="Enter Comments" />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Verify
                </CustomButton>
              </Space>
            </FormItem>
          </>
        ) : (
          ''
        )}
      </Form>
    </CustomModal>
  );
};
export default DeleteMaterialsModel;
