import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Space, Empty, Input, message } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import {
  ArrowDownOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import Layout from '@/common/Layout';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch } from 'react-redux';
import { createNewBin, getAllBinList, generateBin } from '../../redux/slice';

const generateLable = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  // const [bin, setBin] = useState('');
  const [page, setPage] = useState(1);
  const [binList, setBinList] = useState([]);
  const [bins, setbins] = useState([{ bin_number: '' }]);
  const [pagination, setPagination] = useState('');

  useEffect(() => {
    handleGetAllbinList();
  }, [page]);

  const handleGetAllbinList = () => {
    const payload = { page: page };
    dispatch(getAllBinList(payload)).then(function (response) {
      if (response?.payload?.success) {
        setBinList(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleGenerate = (bin) => {
    const payload = { bin_number: bin };
    dispatch(generateBin(payload)).then(function (response) {
      if (response?.payload?.success) {
        handleGetAllbinList();
      }
    });
  };

  const onFinish = (values) => {
    values['bin_no'] = bins;
    dispatch(createNewBin(values)).then(function (response) {
      if (response?.payload?.success) {
        // setTimeout(() => {
        console.log(response?.payload?.data);
        message.success(response?.payload?.message);
        setbins([{ bin_number: '' }]);
        handleGetAllbinList();
        // }, 2000);
      }
    });
  };

  const columns = [
    {
      title: 'Bin Number',
      dataIndex: 'bin_number',
      key: 'bin_number',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.bin_number?.localeCompare(b?.bin_number),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Gererate lable',
      dataIndex: 'bin_number',
      key: 'bin_number',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.number?.localeCompare(b?.number),
      render: (text, record) => {
        return (
          <CustomButton
            type="primary"
            ghost
            onClick={() => handleGenerate(text)}
            disabled={record?.bin_label ? true : false}
          >
            Generate Label
          </CustomButton>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Download',
      dataIndex: 'bin_label',
      key: 'bin_label',
      width: '150px',
      ellipsis: true,
      sorter: (a, b) => a?.number?.localeCompare(b?.number),
      render: (text, record) => {
        return (
          <CustomButton
            type="primary"
            ghost
            onClick={() => handleGenerate(text)}
            disabled={record?.bin_label ? false : true}
          >
            <a href={text}>
              <ArrowDownOutlined /> Download
            </a>
          </CustomButton>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>There are no containers in this bin</span>}
        />
      </span>
    )
  };

  const hanldeAddbin = (e, index) => {
    const list = [...bins];
    list[index].bin_number = e.target.value;
    setbins(list);
    console.log('aaa');
  };

  const hanldeAddbinposition = () => {
    setbins([...bins, { bin_number: '' }]);
    console.log('aaa');
  };

  const hanldedeletebinposition = (index) => {
    const list = [...bins];
    list.splice(index, 1);
    setbins(list);
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title="Generate Bin QR Labels" className={styles.CustomPanel}>
          {bins?.map((bin, index) => {
            return (
              <>
                <Row gutter={24}>
                  <Col xs={10} xl={6} span={6}>
                    <FormItem
                      lable="Enter the Bin Number"
                      // name="bin_number"
                      rules={[
                        {
                          required: false,
                          message: 'Enter damaged containers'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter the Bin Number"
                        onChange={(e) => hanldeAddbin(e, index)}
                        value={bin?.bin_number}
                      />
                    </FormItem>
                  </Col>
                  {console.log(bins?.length)}
                  {bins.length === index + 1 ? (
                    <Col xs={10} xl={6} span={6}>
                      <PlusCircleOutlined
                        onClick={() => hanldeAddbinposition()}
                      />
                    </Col>
                  ) : (
                    <Col xs={10} xl={6} span={6}>
                      <DeleteOutlined
                        onClick={() => hanldedeletebinposition(index)}
                      />
                    </Col>
                  )}
                </Row>
              </>
            );
          })}
          <FormItem className="text-center">
            <Space>
              <CustomButton type="primary" ghost onClick={() => history.back()}>
                Cancel
              </CustomButton>
              <CustomButton type="primary" htmlType="submit">
                Add
              </CustomButton>
            </Space>
          </FormItem>
        </Card>
        <Card
          title={<span>Bin Numbers</span>}
          className={`${styles.cardStyles_approver}`}
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              className={styles.tableStyles}
              columns={columns}
              dataSource={binList}
              // className={styles.scrolling}
              rowKey={(record) => record.id}
              scroll={{ x: 'auto' }}
              locale={locale}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </div>
        </Card>
      </Form>
    </Layout>
  );
};
export default generateLable;
