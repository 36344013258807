import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Space,
  Upload,
  Button,
  Card,
  message,
  Select,
  InputNumber,
  Spin
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { PaperClipOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { createDms, getAllBatches, createFileDms } from './redux/slice';
import Layout from '@/common/Layout';

const DMS = ({ loading }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState();
  const [allBatches, setAllBatches] = useState([]);
  const [docType, setDocType] = useState('');
  const [printOptions, setPrintOptions] = useState('');
  const [loadSpin, setLoadSpin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllBatches();
  }, []);

  const handleGetAllBatches = () => {
    dispatch(getAllBatches()).then((res) => {
      if (res?.payload?.success) {
        setAllBatches(res.payload.data);
      }
    });
  };
  const handleOnChangePdfUpload = (info) => {
    setFileList(info.fileList);
  };

  console.log(fileList);
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    setLoadSpin(true);
    if (values.type === 'new_print') {
      let formData = new FormData();
      formData.append('type', values.type);
      formData.append('product_batch', values.product_batch);
      formData.append('file', fileList[0].originFileObj);
      dispatch(createDms(formData)).then(function (response) {
        if (response?.payload?.success) {
          setLoadSpin(false);
          message.success(response?.payload?.message);
          form.resetFields();
          window.open(response?.payload?.data, '_blank');
        }
      });
    } else {
      let payload = {
        type: values.type,
        id: values.product_batch,
        page_options: values.print_option,
        page_range: [
          parseInt(values.Page_range_from),
          parseInt(values.Page_range_to)
        ]
      };
      dispatch(createFileDms(payload)).then(function (response) {
        if (response?.payload?.success) {
          setLoadSpin(false);
          message.success(response?.payload?.message);
          form.resetFields();
          window.open(response?.payload?.data, '_blank');
        }
      });
    }
  };

  const handleChangeDoc = (e) => {
    setDocType(e.target.value);
  };

  const handlePrintoption = (e) => {
    setPrintOptions(e.target.value);
  };

  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className={styles.panelborder}
      >
        <Card
          title="DOCUMENT MANAGEMENT SYSTEM"
          key="1"
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Document type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group
                  className={styles.customRadio}
                  name="doc_type"
                  onChange={(e) => handleChangeDoc(e)}
                >
                  <Radio value="new_print">New Document</Radio>
                  <Radio value="re_print">Re-Print of The Document</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {docType === 'new_print' ? (
              <>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Batch Number"
                    name="product_batch"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter Batch number" />
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Upload a File"
                    // name="coa_coc_msda_available"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Upload
                      // accept=".pdf"
                      // fileList
                      className={`avatar-uploader ${styles.upload}`}
                      onChange={handleOnChangePdfUpload}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok');
                        }, 0);
                      }}
                      multiple={false}
                      showUploadList={true}
                    >
                      <Button
                        icon={<PaperClipOutlined />}
                        className={styles.upload_button}
                        style={{ width: '100%' }}
                      >
                        Attach File
                      </Button>
                    </Upload>
                  </FormItem>
                </Col>
              </>
            ) : docType === 're_print' ? (
              <>
                <Col xs={24} xl={6} span={6}>
                  <FormItem
                    label="Batch Number"
                    name="product_batch"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Batch number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select>
                      {allBatches?.map((batch) => (
                        <Select.Option key={batch.id} value={batch.id}>
                          {batch.dms_batch_no}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={4} span={4}>
                  <FormItem
                    label="Print option"
                    name="print_option"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      className={styles.customRadio}
                      name="print_option"
                      onChange={(e) => handlePrintoption(e)}
                    >
                      <Radio value="all">All</Radio>
                      <Radio value="pages">Pages</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {printOptions === 'pages' && (
                  <Col xs={24} xl={6} span={6}>
                    <FormItem label="Page Numbers">
                      <Space>
                        <FormItem name="Page_range_from">
                          <InputNumber
                            style={{ marginRight: '8px' }}
                            placeholder="Pg. from"
                          />
                        </FormItem>
                        <FormItem name="Page_range_to">
                          <InputNumber
                            style={{ marginRight: '8px' }}
                            placeholder="Pg. to"
                          />
                        </FormItem>
                      </Space>
                    </FormItem>

                    {/* <FormItem
                    label="Pages"
                    name="pages"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter page range" />
                  </FormItem> */}
                  </Col>
                )}
              </>
            ) : (
              ''
            )}
          </Row>

          <FormItem className="text-center">
            <Space>
              <CustomButton
                htmlType="button"
                // onClick={handleStandardCancel}
                className={styles.border_button}
              >
                Cancel
              </CustomButton>
              {loadSpin ? (
                <Spin tip="Loading" size="small"></Spin>
              ) : (
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.border_button}
                >
                  Print
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Card>
        {/* </Space> */}
      </Form>
    </Layout>
  );
};
export default DMS;
