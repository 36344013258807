import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import { Form, Card, Space, Input } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllCostingetails } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';

const { Search } = Input;

const MaterialCostingList = ({ loading }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [allCostings, setAllCostings] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    handleGetAllCostingDetails();
  }, [page, search]);

  const handleGetAllCostingDetails = () => {
    const payload = { page: page, search: search };
    dispatch(getAllCostingetails(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllCostings(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'material',
      key: 'material',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return (
          <a href={`/create-costing?id=${record?.id}`}>
            {record?.material?.code}
          </a>
        );
      }
    },
    {
      title: 'Inhouse Batch #',
      dataIndex: 'inhouse_batch',
      key: 'inhouse_batch',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.batch_detail?.inhouse_batch_number;
      }
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoice_num',
      key: 'invoice_num',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Total Price',
      dataIndex: 'total_price',
      key: 'total_price',
      ellipsis: true,
      width: '100px',
      render: (_, record) => {
        return record?.total_price ? record.total_price : 'NA';
      }
    },
    {
      title: 'Price per Unit',
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      ellipsis: true,
      width: '100px'
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Material Costing Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Invoice #"
                />
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.push('/create-costing')}
                  type="primary"
                >
                  Create
                </CustomButton>
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allCostings}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.back()}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default MaterialCostingList;
