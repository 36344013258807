import React from 'react';
import styles from './index.module.less';
import { Image, Space, Row, Col, Form } from 'antd';
import updateBinIcon from '@/images/update-bin-position.png';
import Trackbinposition from '@/images/Track-bin-position.png';
import Viewbinfreecontainers from '@/images/view-bin-free-containers.png';
import GenerateBinQRLabels from '@/images/Generate-Bin-QR-Labels.png';
// import QCtruck from '../../images/3.svg';
// import CustomButton from '@/common/CustomButton';

const LandingPage = () => {
  const [form] = Form.useForm();
  const cards = [
    {
      //title: 'Update Bin Position',
      url: '/updatebin',
      icon: updateBinIcon
    },
    {
      //title: 'Track Bin Position',
      url: '/trackbin',
      icon: Trackbinposition
    },
    {
      //title: 'View Bin Free Containers',
      url: '/free-containers',
      icon: Viewbinfreecontainers
    },
    {
      //title: 'Generate Bin QR Lables',
      url: '/generate-labels',
      icon: GenerateBinQRLabels
    }
    // {
    //   //title: 'View Container History',
    //   url: '',
    //   icon: QCtruck
    // }
  ];
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          {cards.map((items, index) => {
            return (
              <Col
                xs={6}
                xl={4}
                md={6}
                sm={6}
                span={6}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Space direction="vertical">
                  <Image
                    src={items.icon}
                    preview={false}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `${items.url}`;
                    }}
                    style={{ width: '100% !important' }}
                  />
                  <span>{items.title}</span>
                </Space>
                {/* <Card hoverable className={styles.cardbody}> */}
                {/* <Space direction="vertical">
                <Image
                  src={item.icon}
                  preview={false}
                  style={{ height: '100px', width: '50% !important' }}
                />
                <CustomButton
                  // className={styles.inwardbutton}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.url;
                  }}
                >
                  {item.title}
                </CustomButton>
              </Space> */}
                {/* </Card> */}
              </Col>
            );
          })}
        </Row>
      </Form>
    </>
  );
};

export default LandingPage;
