import React, { useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import { Card, Empty, Input, Space } from 'antd';
import CustomTable from 'common/Content/CustomTable';
// import moment from 'moment';
import Layout from '@/common/Layout';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrep } from '../../redux/slice';

// import { getAllReturnGP } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import { EditOutlined } from '@ant-design/icons';

const { Search } = Input;
const IPAPreperationLog = () => {
  const { returnableData, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.return
  );

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  console.log(returnableData);
  useEffect(() => {
    handleGetAllReturnGatepass();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleGetAllReturnGatepass = () => {
    let payload = {
      search: search,
      page
    };
    // dispatch(getAllReturnGP(payload));
    dispatch(getPrep(payload));
  };

  const columns = [
    {
      title: 'Date of Dispensing',
      dataIndex: 'prep_date',
      key: 'prep_date',
      ellipsis: true,
      width: '150px',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Dispensed Quantity (kg)',
      dataIndex: 'prep_qty',
      key: 'prep_qty',
      width: '150px',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Quantity (in Liters) (A)',
      dataIndex: 'prep_lts',
      key: 'prep_lts',
      ellipsis: true,
      width: '200px',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },

    // {
    //   title: 'Security in Ward#',
    //   dataIndex: 'sec',
    //   key: 'sec',
    //   align: 'center',
    //   width: '150px',
    //   // ellipsis: true,
    //   sorter: (a, b) => a?.po_number?.localeCompare(b?.po_number),
    //   responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    // },
    {
      title: 'Action',
      dataIndex: 'received_by',
      key: 'received_by',
      align: 'center',
      width: '150px',
      // ellipsis: true,
      //sorter: (a, b) => a?.po_number?.localeCompare(b?.po_number),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => (
        <EditOutlined
          onClick={() =>
            history.push(`/create-ipa-preperation-log?id=${record?.id}`)
          }
        />
      )
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Layout sider={false}>
        <Card
          className={`${styles.cardStyles_approver}`}
          title="70% IPA PREPERATION LOG"
          // style={{ fontWeight: 'bold' }}
          extra={
            <Space>
              <CustomButton
                onClick={() => history.push('/create-ipa-preperation-log')}
              >
                Create
              </CustomButton>
              <Search
                onChange={(e) => handleChange(e)}
                className={`w-100 ${styles.searchbar}`}
                justify="start"
                align="start"
                placeholder="Search by material code"
              />
            </Space>
          }
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              locale={locale}
              loading={getAllMaterilLoading}
              columns={columns}
              dataSource={returnableData}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
            />
          </div>
        </Card>
      </Layout>
    </>
  );
};

export default IPAPreperationLog;
