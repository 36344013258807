import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getInvoices, createInvoices, getFG, getPrdDsc } from './api';

const initialState = {};
const actions = {
  GET_INVOICES: 'finance/GET_INVOICES',
  CREATE_INVOICE: 'finance/CREATE_INVOICE',
  GET_FG_DETAILS: 'finance/GET_FG_DETAILS',
  GET_FG_DETAILS_BY_ID: 'finance/GET_FG_DETAILS_BY_ID',
  GET_ALL_PROD_DESCS: 'finance/GET_ALL_PROD_DESCS',
  GET_ALL_CONSIGNEES: 'finance/GET_ALL_CONSIGNEES',
  GET_ALL_COSTINGS: 'finance/GET_ALL_COSTINGS',
  CREATE_COSTINGS: 'finance/CREATE_COSTINGS'
};

export const getAllInvoicedetails = createAsyncThunk(
  actions.GET_INVOICES,
  async (payload) => {
    const response = await getInvoices('invoice_details', payload);
    return response;
  }
);

export const createinvoice = createAsyncThunk(
  actions.CREATE_INVOICE_INVOICES,
  async (payload) => {
    const response = await createInvoices('create_invoicedetails', payload);
    return response;
  }
);

export const getAllfgDetails = createAsyncThunk(
  actions.GET_FG_DETAILS,
  async (payload) => {
    const response = await getFG('search_FGBatch', payload);
    return response;
  }
);

export const getfgDetails = createAsyncThunk(
  actions.GET_FG_DETAILS_BY_ID,
  async (payload) => {
    const response = await getFG('detailed_fg', payload);
    return response;
  }
);

export const getAllProdDescs = createAsyncThunk(
  actions.GET_ALL_PROD_DESCS,
  async (payload) => {
    const response = await getPrdDsc('search_proddesc', payload);
    return response;
  }
);

export const getAllConsignees = createAsyncThunk(
  actions.GET_ALL_CONSIGNEES,
  async (payload) => {
    const response = await getPrdDsc('search_consignee', payload);
    return response;
  }
);

export const createCosting = createAsyncThunk(
  actions.CREATE_COSTINGS,
  async (payload) => {
    const response = await createInvoices('create_materialcost', payload);
    return response;
  }
);

export const getAllCostingetails = createAsyncThunk(
  actions.GET_ALL_COSTINGS,
  async (payload) => {
    const response = await getPrdDsc('get_materialcost', payload);
    return response;
  }
);

export const FinanceUserSlice = createSlice({
  name: 'FinanceUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoicedetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllInvoicedetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllInvoicedetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createinvoice.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createinvoice.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createinvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllfgDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllfgDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllfgDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getfgDetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getfgDetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getfgDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllProdDescs.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllProdDescs.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllProdDescs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllConsignees.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllConsignees.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllConsignees.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCostingetails.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllCostingetails.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllCostingetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createCosting.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(createCosting.fulfilled, (state, action) => {
        state.getAllMaterilLoading = false;

        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createCosting.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllMaterilLoading = false;

        message.error(msg);
      });
  }
});
export default FinanceUserSlice.reducer;
