import { Image, Layout, Menu /* Space */ } from 'antd';
import styles from '@/common/Layout/index.module.less';
import nucleausLogo from './images/Graviti_Logo_1.svg';
import gravitiLogo from './images/logo_backup.svg';
// import Reports from './images/Reportssvg.js';
import UserMenu from './components/UserMenu';
import { Link, NavLink } from 'react-router-dom';
import Templates from './images/Templatessvg';
import Home from './images/Homesvg';
import { useState } from 'react';
import { history } from 'app/history';
// import { getLoggedInUser } from 'utilities/helpers';

const { Header, Content } = Layout;

const adminuserMenu = [
  {
    key: 'dashboard',

    path: '/dashboard',

    icon: <Home />,

    label: 'Home'
  },
  {
    key: 'list',

    path: '/list',

    icon: <Templates />,

    label: 'Receipts'
  },
  {
    key: 'stock-card',

    path: '/stock-card',

    icon: <Templates />,

    label: 'Stock Card'
  }
];

const qcMenu = [
  {
    key: 'dashboard',

    path: '/dashboard',

    icon: <Home />,

    label: 'Home'
  },
  {
    key: 'stock-card',

    path: '/stock-card',

    icon: <Templates />,

    label: 'Stock Card'
  }
  // {
  //   key: 'labelslist',

  //   path: '/labelslist',

  //   icon: <Templates />,

  //   label: 'Labels'
  // }
];
const adminMenu = [
  {
    key: 'dashboard',

    path: '/dashboard',

    icon: <Home />,

    label: 'Home'
  },
  {
    key: 'stock-card',

    path: '/stock-card',

    icon: <Templates />,

    label: 'Stock Card'
  }
];
const superadminMenu = [
  {
    key: 'dashboard',

    path: '/super_admin_dashboard',

    icon: <Home />,

    label: 'Home'
  },
  {
    key: 'stock-card',

    path: '/stock-card',

    icon: <Templates />,

    label: 'Stock Card'
  }
];

const audit_trails = [
  {
    key: 'audit-trails',

    path: '/audit-trails-dashboard',

    icon: <Templates />,

    label: 'Reports'
  }
];

const users_list_view_only = [
  {
    key: 'users-list-view-only',

    path: '/users-list-view-only',

    icon: <Templates />,

    label: 'Users List'
  }
];

const CustomLayout = ({
  children,
  sider = true,
  header = true,
  menuItems = true
}) => {
  const [selectedMenuKeys] = useState(
    history.location.pathname.replace('/', '')
  );
  const user = JSON.parse(localStorage.getItem('Gravity_user'));
  const headermenu =
    user?.user?.role === 'user' && user?.user?.level == 3
      ? adminuserMenu.concat(audit_trails).concat(users_list_view_only)
      : user?.user?.role === 'user' && user?.user?.level !== 3
      ? adminuserMenu.concat(users_list_view_only)
      : user?.user?.role == 'qa_initiator' && user?.user?.level == 3
      ? qcMenu.concat(audit_trails).concat(users_list_view_only)
      : user?.user?.role == 'qa_initiator' && user?.user?.level !== 3
      ? qcMenu.concat(users_list_view_only)
      : user?.user?.role == 'qc_initiator' && user?.user?.level == 3
      ? qcMenu.concat(audit_trails).concat(users_list_view_only)
      : user?.user?.role == 'qc_initiator' && user?.user?.level !== 3
      ? qcMenu.concat(users_list_view_only)
      : user?.user?.role == 'admin' && user?.user?.level == 3
      ? adminMenu.concat(audit_trails)
      : user?.user?.role == 'prod_user' && user?.user?.level == 3
      ? adminMenu.concat(audit_trails).concat(users_list_view_only)
      : user?.user?.role == 'qa_super_user'
      ? superadminMenu
      : adminMenu.concat(audit_trails).concat(users_list_view_only);
  return (
    <Layout
      className={`${
        sider ? styles.custom_layout_container : styles.custom_layout_container1
      }`}
      hasSider={sider}
    >
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">
              <Link to="/dashboard">
                <Image
                  className={`${styles.logo_header}`}
                  alt="Graviti logo"
                  src={nucleausLogo}
                  preview={false}
                />
              </Link>
            </div>

            {menuItems ? (
              <>
                <Menu
                  className={` df-jc-ac ${styles.menu}`}
                  mode="horizontal"
                  defaultSelectedKeys={['dashboard']}
                  selectedKeys={[selectedMenuKeys]}
                >
                  {headermenu.map((item) => {
                    return (
                      <Menu.Item key={item.key}>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? `${styles.active}` : `${styles.inactive}`
                          }
                          to={item.path}
                        >
                          <span className={` ${styles.icon}`}>
                            {item.icon} {item.label}
                          </span>
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
                </Menu>
                {/* <Image
                  className={`${styles.gr_logo_header}`}
                  alt="Graviti logo"
                  src={gravitiLogo}
                  preview={false}
                /> */}
                <UserMenu />
              </>
            ) : (
              <Image
                className={`${styles.gr_logo_header}`}
                alt="Graviti logo"
                src={gravitiLogo}
                preview={false}
              />
            )}
          </Header>
        ) : null}

        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
